import { Col, Divider, Row } from "antd";
import { useEffect, useState } from "react";
import { getAuthRequest, getRequest, getUserData, postRequest } from "../../services";
import { GET_NOTIFICATION_DETAILS, GET_NOTIFICATION_READ_DETAILS } from "../../services/ApiConstant";

const AmbNotification = () => {
    const [notification, setNotification] = useState([])
    const [showNotification, setShowNotification] = useState([])
  const [showMore, setShowMore] = useState(false)

    useEffect(() => {
        getUserData(GET_NOTIFICATION_DETAILS).then((res) => {
            setNotification(res?.data)
        })
    }, [showNotification])

    const readNotification = (item) => {
        setShowNotification(item)
        setShowMore(true)
        postRequest(GET_NOTIFICATION_READ_DETAILS + `?notification_id=${item.id}`).then((res) => {
        })
    }
    const GetDate = (date) => {
        const gotDate = new Date(date)
        const convertedDate = new Date(gotDate);
        return (date !== undefined && 
        <div>
            <div className="notification-date">
            <span>Date : </span> 
            <span style={{marginLeft:'2px'}}> {convertedDate.toLocaleDateString()}<br /></span>
            </div>
            <div className="notification-time">
             <span>Time : </span>
              <span style={{marginLeft:'2px'}}>{convertedDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span> 
              </div>
              </div>)
    }

    const Capitalize=(str)=>{
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    }

    return (
        <>
            <Row style={{ margin: 30, }} >
                <Col xs={24} sm={24} md={24} lg={8}>
                    {/* {notification.length >0 &&
                        notification?.map((item) => (
                            <div className="notification-box" onClick={()=>{readNotification(item?.id)}}>
                                <div>
                                    <div className="headline">{item?.title}</div>
                                    {item?.read_status && <div className="unread">Unread</div>}
                                </div>
                                <div>
                                    <div className="message">{item?.details}</div>
                                    <div className="date">4/6/2019 4:26 am</div>
                                </div>
                            </div>

                        ))
                    } */}


                    <div className="notificationboxwrap">
                        {notification.map((item) => {
                            return <div className={!item?.read_status ?"notification-box":"notification-box afterRead"} onClick={() => { readNotification(item) }}>
                                <div className="info" style={{cursor:'pointer'}}>
                                    <h4>{(item?.title.length)>30 && !showMore ?Capitalize(item.title.substring(0,30))+"...":Capitalize(item.title)}</h4>
                                    <p>{item?.details.substring(0, 100)}</p>
                                </div>
                                <div className="unreadbox">
                                    {!item?.read_status && <p>Unread</p>}
                                    {/* <span>{item?.updated_time}</span> */}
                                    {/* {GetDate(item?.updated_time)} */}
                                </div>
                            </div>
})}
                    </div>
                </Col>
                {/* <Divider type="vertical"></Divider> */}
                {<Col span={16}>
                    <div className="notificationtext">
                        <h3>{Capitalize(showNotification?.title)?Capitalize(showNotification?.title):''}</h3>
                        {showNotification != undefined && GetDate(showNotification.updated_time)}
                        <p>{showNotification.details}</p>
                    </div>
                </Col>}
            </Row>
        </>

    );
};

export default AmbNotification;
