import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getRequest, postAuthRequest, postRequest } from "../../services";
import { EXAM_BODY, POST_USER_CATEGORY, TRANSACTION } from "../../services/ApiConstant";
import { RiShoppingCartLine } from "react-icons/ri";
import { message } from "antd";
import { Button, Modal } from "antd";
import { CheckCircleOutlined, CheckOutlined } from "@ant-design/icons";

const LevelExam = ({ item }) => {
  const [exams, setExams] = useState()
  const [error, setError] = useState()
  const [messageApi, contextHolder] = message.useMessage()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getRequest(EXAM_BODY + `/?category=${item?.id}`).then((res) => {
      setExams(res.data)
    });
  }, [])

  const subcribeForExam = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    postRequest(POST_USER_CATEGORY + `?category_id=${item?.id}`).then((res) => {
      payment(item?.id)
    }).catch((e) => {
      setError(e.response.data.error[0])
    })
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const payment = (id) => {
    postAuthRequest(TRANSACTION, { category_id: id }).then((res) => {
      res.data.message != undefined && PaymentInfo();
      window.location.href = res.data.result.data.authorization_url;
    }
    ).catch((e) => {
    })
  }

  const PaymentInfo = () => {
    // messageApi.info('Copied');
    messageApi.open({
      type: 'success',
      content: 'Subscribed to plan',
      onClose: () => {
        navigate('/dashboard/home');
      }
    });
  };
  return (
    <div className="other-exams">
      {contextHolder}
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={316}
        footer={[
          <Button key="back" onClick={handleCancel}>
            No
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk} style={{ marginLeft: '25px' }}>
            Yes, Subscribe
          </Button>,
        ]}
      >
        <div>

          <p style={{ color: "#002978" }}>
            <img src="../images/alert.png" /> Are you sure?
          </p>
          <br />
          <p className="pb-3">
            Are you sure you want to continue to subscribe?
          </p>

          <div> Subscription price - {Number(item.price).toFixed(2)}</div>
          <div> Category - {item.name}</div>
        </div>
      </Modal>
      {<div className="error-msg">{error}</div>}

      <div className="recommend-exam-btn-wrap">
        <div className="" >
          {item?.is_subscribed ? <button className="subscribe-btn w-auto d-flex direction-row"><CheckCircleOutlined style={{ color: "green" }} /> &nbsp;&nbsp; Already Subscribed </button> : <button className="subscribe-btn w-auto d-flex direction-row" onClick={() => subcribeForExam()}><RiShoppingCartLine />&nbsp;&nbsp; <span>Subscribe to {item?.name}</span></button>} </div>
      </div>
      <div className="exam-left">
        {exams?.map((item) => (
          <div className="recommend-exam dsdfs">
            <div className="recommend-exam-wrap">
              <div className="recommend-image">
                <img src={item?.icon} />
              </div>
              <div className="recommend-info">
                <div className="r-exam-name">
                  <p>Year {item.first_year}-{item.last_year}</p>
                  <h2>{item.full_name}</h2>
                  {/* <p style={{color:'#2F327D',fontSize:13}}>₦{item.price}</p> */}
                </div>
              </div>
            </div>
            {/* <div className="recommend-exam-btn-wrap">
            <div className="buy subscribe me-0" onClick={()=>{subcribeForExam({id:item.id,name:item.name,isUtme:item.is_utme})}}>
              <RiShoppingCartLine />
              <p>Subscribe </p>
            </div>
          </div> */}
          </div>
        ))}
      </div>


    </div>
  );
};

export default LevelExam;
