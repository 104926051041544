import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { CiSearch } from 'react-icons/ci';
import { Button, Input, Modal } from 'antd'
import { getAuthRequest } from "../../services";
import { PROFILE_API } from "../../services/ApiConstant";
import sagar from "../../assets/images/clarity_help-info-solid.png"




const Header = ({ step, page, exam ,examsimulation,updatedData,userProfileImage }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [fullData, setFullData] = useState()

  const getData = () => {
    !examsimulation && getAuthRequest(PROFILE_API, {}).then((res) => {
      setFullData(res.data)
      setStatus(true)
    }).catch((e) => {
    })
  }
  useEffect(() => {
    getData()
  }, [])

  const [modal1Open, setModal1Open] = useState(false);
  const help=()=>{
    setModal1Open(true)
  }

  const clickHandler=()=>{
    navigate('/dashboard/profile-setting')
  }

  return (
    <div className='header stickyhead row-between p-20'>
      <div className={examsimulation ? "logo-box-help" :`logo-box`}>
        <img className="logo" src={require("../../assets/images/logo.png")} alt="icon"/>
      </div>
    
      {!exam && <Input size="small" style={{ width: '40%' }} placeholder="Search...." prefix={<CiSearch size={'18px'} color="#7F97BD" />} />}
      {!exam &&
        <div className="user-info-wrap profile-cursor" onClick={clickHandler}>
          <div className="tham">
            {/* <img className='profile-img' src={fullData?.profile_image===null?require("../../assets/images/default-user-icon.png"): userProfileImage==undefined?fullData?.profile_image:userProfileImage} /> */}
            <img className="profile-img" src={userProfileImage || fullData?.profile_image || require("../../assets/images/default-user-icon.png")} alt="logo"/>
          </div>
          {status ?
            <div className="user-info">
              <h3>{updatedData?.full_name?updatedData?.full_name:fullData?.full_name}</h3>
              <p>{fullData?.user?.email}</p>
            </div> : ""}
        </div>
      }
      {examsimulation &&<Button  onClick={() => help(true)}>Help <img src={require("../../assets/images/clarity_help-info-solid.png")} /> </Button>  }

      {examsimulation &&<Modal
          title="help"
          style={{
            top: 20,
            right:10
          }}
          open={modal1Open}
          onCancel={() => setModal1Open(false)}
        >
          <div className="helpModal">
            <i>Lorem ipsum dolor sit amet consectetur. Consectetur diam morbi facilisis vivamus nisl a ornare vestibulum. Suspendisse turpis vel condimentum scelerisque dolor diam arcu arcu purus. Sed ut urna at fames convallis pretium. Semper porttitor egestas nulla a amet consequat. Nam malesuada amet ornare est maecenas a odio ullamcorper. </i>
            <div className="exQupanel">
              <div className="qutext">
                <h3>Lorem ipsum dolor sit amet consectetur. Consectetur diam morbi facilisis vivamus nisl?</h3>
              </div>
              <div className="quoplist">
                <ul className="cusradiolist">
                  <li>
                    <input type="radio" id="quisodio1" name="quisodio" value="" />
                    <label for="quisodio1">(A)  Consectetur quis odio</label>
                  </li>
                  <li>
                    <input type="radio" id="quisodio2" name="quisodio" value="" />
                    <label for="quisodio2">(B)  Consectetur quis odio</label>
                  </li>
                  <li>
                    <input type="radio" id="quisodio3" name="quisodio" value="" />
                    <label for="quisodio3">(C)  Consectetur quis odio</label>
                  </li>
                  <li>
                    <input type="radio" id="quisodio4" name="quisodio" value="" />
                    <label for="quisodio4">(D)  Consectetur quis odio</label>
                  </li>
                  <li>
                    <input type="radio" id="quisodio5" name="quisodio" value="" />
                    <label for="quisodio5">(E)  Consectetur quis odio</label>
                  </li>
                </ul>
              </div>
            </div>
            <p>Lorem ipsum dolor sit amet consectetur. Consectetur diam morbi facilisis vivamus nisl?</p>
          </div>
        </Modal>}

    </div>

  );
};

export default Header;
