import React from "react";
import AuthCard from "../../../Components/AuthCard";
import { Link, useNavigate } from "react-router-dom";

import { Input } from "antd";
import { Form, Checkbox, Button } from "antd";
import { postRequest } from "../../../services";
import { LOGIN_API } from "../../../services/ApiConstant";

const RunASOption = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const asStudent=()=>{
    localStorage.setItem('userType',1)
    navigate('/Signin')
  }
  const asAmbassodor=()=>{
    localStorage.setItem('userType',2)
    navigate('/Signin')
  }
  

  return (
    <AuthCard
      link="/create-account"
      linkText={"Create an Account"}
      subtitle="Don’t have an account? click here to"
      bottom={true}
      loginPage={true}
      isHide={true}
    >
        <Form form={form} >
        <div className="action-button-lists">
        <Button className="auth-btn" type="primary" onClick={()=> asStudent()} loading={isLoading}>
          {" "}
          Login As a Student{" "}
        </Button>
        <Button className="auth-link-btn" onClick={()=> asAmbassodor()} loading={isLoading}>
          {" "}
          Login As An Ambassodor{" "}
        </Button>
        </div>
      </Form>
      
    </AuthCard>
  );
};

export default RunASOption;
