import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GET_ANSWERS_BY_QUESTION_IDS, GET_QUESTIONS_BY_TESTPAPER_IDS, GET_USER_TESTPAPER_STATUS, RESUME_TESTPAPER_BY_TESTPAPER_IDS } from "../../../services/ApiConstant";
import { getUserData, postRequest } from "../../../services";
import { Modal } from "antd";
import Calculator from "../../../assets/common/Calculator";
import Question from "../../../assets/common/Question";
import Options from "../../../assets/common/Option";

const ExamQuestion = () => {

    const [questions, setQuestions] = useState();
    const navigate = useNavigate();
    const [questionNumber, setQuestionNumber] = useState();
    const [result, setResult] = useState()
    const [ans, setAns] = useState()
    const [group, setGroup] = useState([])
    const { confirm } = Modal;
    const [timeOver, setTimeOver] = useState(false)
    const [timer, setTimer] = useState()
    const [clear, setClear] = useState(true)
    const [nav, setNav] = useState(false)
    const [calculator, setCalculator] = useState(false)
    let testPaperID = localStorage.getItem("testPaperID")
    let examID = localStorage.getItem("examID")
    let time = localStorage.getItem("ExamTime")
    let examName = localStorage.getItem("examName")
    let exam = localStorage.getItem("exam")
    const [takenTime, setTakenTime] = useState();
    const abcd = ["A", "B", "C", "D", "E"]

    useEffect(() => {
        getUserData(GET_QUESTIONS_BY_TESTPAPER_IDS + `?testpaper_id=${testPaperID}`).then((res) => {
            setQuestions(res.data)
            setGroup(res?.data?.map((i) => ({ "question_id": i.question, "answer": "", "attempted": false })))
        });
    }, [])

    const gettingAns = (e, question_id) => {
        group?.map((i) => {
            if (i.question_id === question_id) {
                i.answer = e.target.value
                i.attempted = true
            }
        })
        setTimeOver(!timeOver)
    }

    const getResult = () => {
        if (group.length > 0) {
            postRequest(GET_ANSWERS_BY_QUESTION_IDS + `?testpaper_id=${testPaperID}&exam_id=${examID}`, group).then((res) => {
                setResult(res.data)
                setNav(true)
            });
            postRequest(GET_USER_TESTPAPER_STATUS + `?testpaper_id=${testPaperID}`).then((res) => {
            });
        }
    }
    useEffect(() => {
        if (nav) {
            showConfirm()
        }
    }, [nav])

    const onSubmit = () => {
        getResult()
    }

    function myStopFunction() {
        setClear(false);
    }

    useEffect(() => { Timer(time) }, [group])

    let x = null
    const Timer = ((time) => {
        time = time * 60
        let seconds = time % 60;
        let minutes = Math.floor((time / 60) % 60);
        let hours = Math.floor(time / 3600);
        x = setInterval(function () {
            if (seconds === 0) {
                if (minutes === 0) {
                    if (hours === 0) {
                        if (time <= hours + minutes) {
                            clearInterval(x);
                            onSubmit()
                        }
                        return;
                    }
                    hours -= 1;
                    minutes = 59;
                } else {
                    minutes -= 1;
                }
                seconds = 59;
            } else {
                seconds -= 1;
            }
            function n(n) {
                return n > 9 ? "" + n : "0" + n;
            }
            setTimer(`${n(hours)}:${n(minutes)}:${n(seconds)}`)
        }, 1000);
    })

    useEffect(() => {
        if (timer !== undefined) {
            const hours = timer.slice(0, 2)
            const minutes = timer.slice(3, 5)
            const seconds = Number(timer.slice(6, 8))
            const milliSecondsTimeWeHave = time * 60 * 1000;
            const milliSecondsTimeWeTaken = (hours * 60 * 60 + 60 * minutes + seconds) * 1000
            const fakeTime = milliSecondsTimeWeHave - milliSecondsTimeWeTaken
            let realTime = msToTime(fakeTime)
            setTakenTime(realTime);
            if (result !== undefined) {
                localStorage.setItem("result", JSON.stringify(result));
                localStorage.setItem("takenTime", takenTime);
                navigate("/exam/result")
            }
        }
    }, [timer, result]);

    function msToTime(duration) {
        var milliseconds = Math.floor((duration % 1000) / 100),
            seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
        return hours + ":" + minutes + ":" + seconds
    }

    const showConfirm = () => {
        if (result !== undefined) {
            localStorage.setItem("result", JSON.stringify(result))
            localStorage.setItem("takenTime", timer)
            navigate("/exam/result")
        }
    };

    const ansChecking = (ans, question) => {
        let status = false
        group?.map((i) => {
            if (i.question_id == question) {
                status = i.answer == ans
            }
        })
        return status
    }

    window.location.hash = "no-back-button";
    window.onhashchange = function () {
        window.location.hash = "no-back-button";
    }

    const handleCopyPaste = (event) => {
        event.preventDefault()
    }

    return (
        <>
            <div className="jabm-sec">
                <div className="jabm-container">
                    <div className="exQulistwap">
                        {/* <div onClick={() => setCalculator(!calculator)}> calculator </div> */}
                        <div className="calculator-box" onClick={() => setCalculator(!calculator)}>
                            <div className="cal-icon" >
                                <img src="/images/fa-solid_calculator.png" alt="" style={{ display: "inline" }} /> Calculator
                            </div>
                        </div>
                        {calculator && <Calculator />}
                        <div className="Timer"> {timer && <p className="d-flex align-items-center"><img src="../images/timer-icon.png" alt="" />&nbsp; {timer}</p>}</div>
                        {exam=="SAT"?<div><h1>{examName} Test</h1> <br/> <h4> {time} MINUTES, {questions?.length} QUESTIONS </h4> <br/> </div>:<h2>NOVEMBER 1989 SSCE COMMERCE OBJECTIVE</h2>}
                        {questions?.map((i, index) => (
                            <>
                                {i.diagram && <div className="exQucusupload mb-4">
                                    <i>Use the diagram below to answer question 1, 2 and 3</i>
                                    {/* <input type="file" name="" value="" id="upload" /> */}
                                    <label htmlFor="upload">
                                        <img src={i?.diagram} alt="" />
                                    </label>
                                </div>}
                                {questions[index - 1]?.question_details.question_explaination != i.question_details.question_explaination && <div className="exQucusupload mb-4">
                                    {i.question_details.question_explaination}
                                </div>}
                                {/* {questions[index-1]?.question_details.question_explaination} */}
                                <div className="exQupanel">
                                    <div className="qutext" >
                                        <h3 onPaste={handleCopyPaste} onCopy={handleCopyPaste} onContextMenu={handleCopyPaste}>
                                        <Question question={i.question_details.question_text} no={index + 1}/>  
                                        </h3>
                                    </div>
                                    <div className="quoplist">
                                        {i?.question_details.question_note && <div className="exQucusupload mb-4 note-bold">
                                            {i?.question_details.question_note}
                                        </div>}
                                        <ul className="cusradiolist">
                                            {
                                                i.answer?.map((j, index) => (
                                                    j.ans !== null && <li>
                                                        <input type="radio" id={j.ans+i?.question} name={i?.question} value={j.ans} onChange={(e) => gettingAns(e, i?.question)} checked={ansChecking(j.ans, i?.question)} />
                                                        <label htmlFor={j.ans+i?.question}> <Options option={j?.ans} index={index}/></label>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </>
                        ))}
                        <div className="qucomm-btn-wrap">
                            <button className="btn" onClick={() => onSubmit()}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExamQuestion;
