import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Components/Header";
import AmbassodorSidebar from "./Components/Sidebar/amb-Sidebar";
import AmbassodorHeader from "./Components/Header/amb-header";

const AmbassodorLayout = ({profileImage,setIsLoggedIn}) => {
  const [mobileMenu,setMobileMenu]=useState(true)
  return (
    <div className={mobileMenu?"ambassodorBody":"ambassodorBody menuOpen"}>
      <AmbassodorSidebar setIsLoggedIn={setIsLoggedIn} setMobileMenu={setMobileMenu} profileImage={profileImage}/>
      <div className="ambassodormain">
        <AmbassodorHeader setMobileMenu={setMobileMenu} />
        <div className="ambmainwrap">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AmbassodorLayout;