import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthHeader from "../AuthHeader";
import { useLocation } from "react-router-dom";
const AuthCard = ({ step, page, isHide, children, subtitle, link, linkText, bottom, loginPage }) => {
  const location = useLocation()
  const pathName = location.pathname
  const currentComponent = pathName.substring(1)
  const [logo, setLogo] = useState(true);

  console.log(loginPage)

  useEffect(() => {
    let role = "";
    role = localStorage.getItem("userType");
    if (role == 1) {
      setLogo(true);
    }
    if (role == 2) {
      setLogo(false);
    }
  }, [])

  return [
    <>
      {!isHide && (
        <AuthHeader {...{ step, page, currentComponent }} />
      )}

      <div className="signin-container-wrap">
        <div className={logo?"signin-container": "signin-container for-ambassador"}>
          {/* <img className="logo" src={require(logo?"../../assets/images/logo.png":"../../assets/images/Ambassador-logo.png")} /> */}
          {logo?  <img className="logo" src="/images/logo.svg" alt="" /> : <img className="logo" src="/images/Ambassador-logo.svg" alt="" /> }
          {!loginPage && <h1 className={logo?"": "for-ambassador"}>Hello, Welcome back</h1>}
         {!loginPage && <p className="signup_more_link">
            {subtitle}&nbsp;
            <Link to={link}>
              <span>{linkText}</span>
            </Link>
          </p>}
          
          
          <div className="form-content-wrapper">
            {children}
          </div>
        </div>
         {/* {loginPage && <p className="signup_more_link">
            {subtitle}&nbsp;
            <Link to={link}>
              <span>{linkText}</span>
            </Link>
          </p>} */}
          
         
              {loginPage && <p className="signup_more_link">
                {subtitle}&nbsp;
                <Link to={link}>
                  <span>{linkText}</span>
                </Link>
              </p>}
              
      
      </div>

    </>
  ]
};

export default AuthCard;
