import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import './assets/css/style.css';
import './assets/css/responsive.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const handleCopyPaste=(event)=>{
    event.preventDefault()
  }
root.render(
  <div onCopy={handleCopyPaste} onContextMenu={handleCopyPaste}>
    <App />
    </div>
 
);

reportWebVitals();
