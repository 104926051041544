import { useEffect, useRef, useState } from "react";
import { getUserData, postRequest } from "../../services";
import { GET_ANSWERS_BY_QUESTION_IDS, GET_QUESTION_IELTS_LISTENING, IELTS_GET_ANSWER_BY_USER_TESTPAPER } from "../../services/ApiConstant";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import Question from "../../assets/common/Question";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const IELTSListeningAns = () => {
    const testpaperId = localStorage.getItem("testPaperID")
    const [currentAudio, setCurrentAudio] = useState()
    const [allData, setAllData] = useState([])
    const [taskNo, setTaskNo] = useState(0)
    let examID = localStorage.getItem("examID")
    const navigate = useNavigate()
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [backModalOpen, setBackModalOpen] = useState(false)
    const [answer, setAnwser] = useState([])

    useEffect(() => {
        if (currentAudio !== undefined) {
            document.getElementById('myaudio').volume = localStorage.getItem("volume")
            document.getElementById('myaudio').play();
        }
        else {
            document.getElementById('myaudio').pause();
        }
    }, [currentAudio])

    useEffect(() => {
        getUserData(GET_QUESTION_IELTS_LISTENING + `?testpaper_id=${testpaperId}`).then((res) => {
            setAllData(res.data)
            setCurrentAudio(res.data[taskNo]?.audio)
        })
    }, [])

    const questionNumberButton = (index, status) => {
        setCurrentAudio(allData[index]?.audio)
        setTaskNo(index)
        setCurrentQuestionIndex(index)
    }

    useEffect(() => {
        getUserData(IELTS_GET_ANSWER_BY_USER_TESTPAPER + `?exam_id=${examID}&testpaper_id=${testpaperId}`).then((res) => {
            setAnwser(res.data)
        });
    }, [])

    const ansChecking = (question) => {
        let status = { correct_answer: false, your_answer: false, answer: "" }

        answer[taskNo]?.corrections_ans?.map((i) => {
            if (i.question == question) {

                status.correct_answer = i.correct_answer
                status.your_answer = i.user_answer
                status.answer = i.user_answer
            }
        })
        return status.answer
    }
    const getAnswerStatus = (question_no) => {
        let answer_no = answer[taskNo]?.corrections_ans?.filter((i) => {
            return i?.question === question_no
        })
        if (answer_no !== undefined) {
            return answer_no[0]?.correct_answer === answer_no[0]?.user_answer
        }
    }

    return (
        <>
            <div className="ielts-sim-header">
                <div className="sim-ttl">
                    <h3>IELTS Simulator</h3>
                </div>
                <audio id="myaudio" style={{ display: "none" }} src={currentAudio} controls></audio>
                <div className="sim-btn-wrap">
                    <button className="btn" onClick={() => navigate("/exam")}>Back</button>
                </div>
            </div>
            <div className="sim-main-ttl">
                <h3>{localStorage.getItem("examName")} Test - {localStorage.getItem("SelectedYear")}</h3>
            </div>
            <div className="ielts-qu-detils">
                <h2>Question 1-{allData[taskNo]?.questions.length}</h2>
                <p>{allData[taskNo]?.paragraph}</p>
                <div className="bg-table mb-4">
                </div>
                <ol className='from-qu-list'>
                    {allData[taskNo]?.questions?.map((i, index) => {
                        return <li key={index} className="d-flex">
                            <small>{index + 1}. </small>
                            <p><Question question={i.question_details.question_text}/></p> &nbsp; &nbsp;  <input type="text" className="list-form-con" placeholder="type correct answer" value={ansChecking(i.question)} />
                            {
                                getAnswerStatus(i?.question) ? <span style={{ marginLeft: '10px', color: 'green' }}><CheckOutlined/></span> : <span style={{ marginLeft: '10px', color: 'red' }}><CloseOutlined/></span>
                            }
                        </li>
                    })}
                </ol>
            </div>

            <div className="task-menu-bar">
                {allData?.map((i, index) => (<button key={index} className={index === taskNo && 'active'} onClick={() => questionNumberButton(index, index === taskNo)}>Task {index + 1}</button>))}
            </div>
        </>
    );
};

export default IELTSListeningAns;