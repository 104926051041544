import MathJax from "react-mathjax-preview"
const abcd = ["A", "B", "C", "D", "E"]

const Options = ({ option, index }) => {
    let q = option?.replace(`xmlns=\\\"http://www.w3.org/1998/Math/MathML\\\"`, ``)
    q = q?.replace(`xmlns=\\"http://www.w3.org/1998/Math/MathML\\"`, ``)
    return (
        <>
            <div className="d-flex">
                {index!==undefined && <span className="">
                 ({abcd[index]})
                </span>}
                &nbsp;
                
                <MathJax math={q} />
            </div>
        </>
    )
}
export default Options
