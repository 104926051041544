import React from "react";
import AuthCard from "../../../Components/AuthCard";

import { Radio, Form, Checkbox, Input } from "antd";
import { Link } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";

const Payments = () => {
  const config = {
    reference: new Date().getTime().toString(),
    email: "user@example.com",
    amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_test_27b5e88779100cd70ab2b2a3dac3abebdfe1c276",
  };

  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
  };

  const PaystackHookExample = () => {
    
    initializePayment(onSuccess, onClose);
  };
  const [form] = Form.useForm();

  return [
    <AuthCard link="/" linkText={""} subtitle="" page="Make Payment" step="04">
      <br />
      <p className="subtitle">Payment</p>
      <br />
      <Form layout="vertical" form={form}>
        <Form.Item label="Pay with">
          <Radio.Group>
            <Radio value="apple"> Card </Radio>
            <Radio value="pear"> Transfer </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Card Number">
          <Input />
        </Form.Item>
        <div className="row-between">
          <Form.Item label="Expiration Date">
            <Input placeholder="MM/YY" />
          </Form.Item>

          <Form.Item label="CVV">
            <Input />
          </Form.Item>
        </div>

        <Form.Item>
          <div className="row-between" style={{ marginTop: -35 }}>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Save card detail</Checkbox>
            </Form.Item>
          </div>
        </Form.Item>
      </Form>
      <br />
      <Link to="/dashboard">
      <button
        className="auth-btn"
        type="button"
      >
        {" "}
        Register{" "}
      </button>
      </Link>
    </AuthCard>,
    <div className="footer-text">
      NOTE: We're excited to offer you a 7-day free trial. You'll have full
      access to the exam of your choice during this time, completely free of
      charge. After the trial period, your account will be automatically billed
      for the subscription fee. You can cancel your subscription within the
      period of this 7 days.
    </div>,
    <br />,
  ];
};

export default Payments;
