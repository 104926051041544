import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../../../services";
import {  GET_BANKS, POST_BANKUPDATE } from "../../../services/ApiConstant";
import { Button, Form, Input, Select } from "antd";

const AmbassodorBankDetails = ({getBankDetails}) => {

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [banks, setBanks] = useState([]);
    const[bankValues,setBankValues]=useState()

    useEffect(() => {
        getRequest(GET_BANKS).then((res) => {
            setBanks(res?.data);
        })
    }, [])

    const onFinish = (values) => {
        setBankValues(values)
        postRequest(POST_BANKUPDATE, values).then((res) => {
            localStorage.setItem('userType', 2)
            localStorage.removeItem('token');
            navigate('/Signin')
        })
    }

    const validatePhoneNumber = (_, value) => {
        const regex = /^\d{10}$/; // Assumes a 10-digit phone number
        if (!regex.test(value)) {
            return Promise.reject("Please enter a valid phone number");
        }
        return Promise.resolve();
    };

    const validateAccountName = (_, value) => {
        const regex = /^[A-Za-z\s]+$/;
        if (!regex.test(value)) {
            return Promise.reject("Account name can only contain letters and spaces");
        }
        return Promise.resolve();
    };

    return [
        <AuthCard
            link="/"
            linkText={"Sign In"}
            subtitle="If you alredy have an account, click here to "
            page="Bank Details"
            step="02"
            loginPage={false}
        >
            <br />
            <p className="subtitle">
                Enter You Bank Details
            </p>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item label="Bank Name" name="bank" rules={[{ required: true, message: 'Please select bank' }]}>
                    <Select>
                        {banks?.map((item) => (
                            <Select.Option value={item.id}>{item.bank_name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Account Number" name="account_number"
                    rules={[{ required: true, message: 'Please your account number', whitespace: true }]}>
                    <Input type="number" placeholder="Enter your account number" />
                </Form.Item>

                <Form.Item label="Account Name" name="account_name"
                    rules={[{ required: true, message: 'Please enter your account name', whitespace: true },{ validator: validateAccountName },]}>
                    <Input placeholder="Enter your name" />
                </Form.Item>
                <Button className="auth-btn" type="primary" onClick={() => form.submit()}>
                    {" "}
                    Done {" "}
                </Button>
            </Form>

        </AuthCard>,

        <div className="footer-text">
            NOTE: We're excited to offer you a 7-day free trial. You'll have full
            access to the exam of your choice during this time, completely free of
            charge. After the trial period, your account will be automatically billed
            for the subscription fee. You can cancel your subscription within the
            period of this 7 days.
        </div>,
    ];
};

export default AmbassodorBankDetails;

