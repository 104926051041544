import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { RiHome5Line } from "react-icons/ri";
import { Modal, Button } from "antd";
import { Logout, postRequest } from "../../services";
import { LOGOUT_API } from "../../services/ApiConstant";

const urls = [
  {
    id: 1, name: "Home", link: "/dashboard/home", icon: "../images/home.svg",
    activeIcon: "../images/home_active.png",
  },
  {
    id: 2,
    name: "Other Exams",
    link: "/dashboard/other-exams",
    icon: "../images/other-exam.svg",
    activeIcon: "../images/book_active.png",
  },
  {
    id: 3,
    name: "Profile Settings",
    link: "/dashboard/profile-setting",
    icon: "../images/setting-2.svg",
    activeIcon: "../images/setting_active.png",
  },
  {
    id: 4,
    name: "Contact Us",
    link: "/dashboard/contact",
    icon: "../images/contact.svg",
    activeIcon: "../images/contact_active.png",
    
  },
  {
    id: 5,
    name: "Notification",
    link: "/dashboard/notification",
    icon: "../images/notification.svg",
    activeIcon: "../images/bell_active.png",
    
  },
];

const Sidebar = ({setIsLoggedIn}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const showModal = () => {
    setOpen(true);
  };
  const logoutModal = () => {
    const handleOk = () => {
      setLoading(true);

      Logout(LOGOUT_API, {}).then((res) => {
        setTimeout(() => {
          setLoading(false);
          setOpen(false);
          localStorage.removeItem('token',res?.data?.token);
          localStorage.removeItem('userType',res?.data?.id)
          setIsLoggedIn(false)
        }, 1000);

      }).catch((e) => {
      })

    };
    const handleCancel = () => {
      setOpen(false);
    };
    return (
      <>
        <Modal
          open={open}
          centered={true}
          title={
            <div className="row">
              <div>
              <img src="../images/alert.png" />
              <span
                style={{
                  color: "#002978",
                  fontSize: 16,
                  fontWeight: 400,
                  marginLeft: 10,
                }}
              >
                Log Out
              </span>
              </div>
            </div>
          }
          onOk={handleOk}
          onCancel={handleCancel}
          width={300}
          footer={
            <div className="w-100">
              {/* <NavLink to="/"> */}
                <Button
                  className="w-100"
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
                >
                  Yes, I want to logout
                </Button>
              {/* </NavLink> */}

              <br />
              <br />

              <Button className="w-100 mt-20" key="back" onClick={handleCancel}>
                No, I don’t want to logout
              </Button>
            </div>
          }
        >
          <br />
          <p>Are you sure you want to Log out?</p>
          <br />
        </Modal>
      </>
    );
  };

  return (
    <>
      <div className="sidebar">
        {/* <ul>
        
        </ul> */}
        {urls.map((url) => (
          <div className="sideitem" key={url.id}>
            <NavLink to={url.link}>
              <div className="d-flex" >
                <div className="manu-icon" >
                  <img style={{ width: 20 }} src={url.link === location.pathname ? url.activeIcon : url.icon} />
                </div>
                <p>{url.name}</p>
              </div>
            </NavLink>
          </div>
        ))}
        <br />
        <br />
        <br />
        <br />
        <div className="sideitem" key={"log-out"}>
          {/* <NavLink to={'/ff'}> */}
          <div onClick={showModal} className="row logout">
            <div style={{ width: 40 }}>
              <img style={{ width: 20 }} src={"../images/logout.png"} />
            </div>
            <div className="warn-color">{"Logout"}</div>
          </div>
          {/* </NavLink> */}
        </div>

        {logoutModal()}
      </div>
      <div className="mobile-menu">
        <ul>
          {urls.map((url)=>(

            <NavLink to={url.link}><li key={url.id}><button><img src={url.link === location.pathname ? url.activeIcon : url.icon} /></button></li></NavLink>

          ))}
          <li key={"log-out"}><button onClick={showModal}><img src={"../images/logout.png"} /></button></li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
