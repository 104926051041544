import React, { useEffect, useState } from "react";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { CiSearch } from 'react-icons/ci';
import { Button, Input, Modal, message, notification } from 'antd'
import { getAuthRequest, getUserData, postRequest } from "../../services";
import { GET_NOTIFICATION_DETAILS, PROFILE_API, WITHDRAWL_REQUEST } from "../../services/ApiConstant";
import { exceptThisSymbols } from "../../assets/common/OnlyNumber";



const AmbassodorHeader = ({ step, page, exam, examsimulation, setMobileMenu }) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState('')
  const [messageApi, contextHolder] = message.useMessage()

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenInfo, setIsModalOpenInfo] = useState(false);
  

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setAmount('')
  };

  const urls = [
    {
      name: "Overview",
      link: "/ambassodor/ambassodor-overview",
    },
    {
      name: "Payments",
      link: "/ambassodor/ambassodor-payment",
    },
    {
      name: "Settings",
      link: "/ambassodor/ambassodor-setting",
    },
    {
      name: "Contact Us",
      link: "/ambassodor/ambassodor-contact",
    },
    {
      name: "Notification ",
      link: "/ambassodor/ambassodor-notification",
    },

  ];

  const craeteRequestForCashOut = () => {
    let cashOut = { amount: amount }

    postRequest(WITHDRAWL_REQUEST, cashOut).then((res) => {
      setIsModalOpen(false);
      setIsModalOpenInfo(true)
    })
    // messageApi.open({
    //   type: 'success',
    //   content: 'Cash out request has been sent for Admin approval',
    // });
    setAmount('')
  }

  const [notification,setNotification]=useState(0)

  useEffect(() => {
    getUserData(GET_NOTIFICATION_DETAILS).then((res) => {
      setNotification(res?.data.length)
    })
  }, [])

  return (
    <div className='ambassodorHeader'>
      {contextHolder}
      <div className="ttlWrap">
        <h3>
          {
            urls.filter((i) => i.link == window.location.pathname)[0].name
          }
        </h3>
      </div>
      <div className="rt-side">
        <div className="cashOut">
          <button onClick={() => { showModal() }} >Cash Out <img src="/images/cash-out-icon.svg" /></button>
        </div>

        <div className="notification" onClick={() => navigate("/ambassodor/ambassodor-notification")} style={{cursor:'pointer'}}>
          <img src="/images/bell-icon.svg" />
          {notification!=0&&<span>{notification}</span>}
        </div>

        <Modal title="" open={isModalOpen} onCancel={handleCancel} className="caseOut-modal" footer={[<div className="modal-btn-wrap">
          <Button className="btn w-100" onClick={() => { craeteRequestForCashOut() }}>Cash out</Button>
        </div>]}>
          <div className="cashOutModalWrap">
            <div className="modal-ttl">
              <h3>How much do you want to cash out?</h3>
            </div>
            <div className="form-group">
              <input type="number" onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} className="form-control" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Enter amount here" />
            </div>
            {/* <div className="modal-btn-wrap">
            <Button className="btn w-100"onClick={()=>{craeteRequestForCashOut()}}>Cash out</Button>
          </div> */}
          </div>
        </Modal>

        <Modal title="" open={isModalOpenInfo} onCancel={() => {setIsModalOpenInfo(false)}} className="caseOut-alert-modal" footer={[<div className="modal-btn-wrap">
          <Button onClick={() => {setIsModalOpenInfo(false)}}>Okay</Button>
        </div>]}>
          <div className="cashOutModalWrap">
           <div className="icon-container">
            <img src="/images/green-circle-tick-bordered.svg" alt=""></img>
           </div>
              <div className="text-container">
                <h3>Cash Out</h3>
                <p>Your request has been sent <br/> Please give us 24 hours to verify your cash out</p>
              </div>
          </div>
        </Modal>
        <div className="menu-toggle" onClick={() => { setMobileMenu(mobileMenu => !mobileMenu) }}>
         <div></div>
        </div>
      </div>
    </div>

  );
};

export default AmbassodorHeader;
