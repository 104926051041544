import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { Link, useNavigate } from "react-router-dom";
import { Input, Modal } from "antd";
import { Form, Checkbox, Button } from "antd";
import { SignIn, postRequest } from "../../../services";
import { ADD_USER_API, REQUEST_OTP, VERIFY_OTP } from "../../../services/ApiConstant";

const CreateAccount = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = useState()
  const [emailValidation, setEmailValidation] = useState()
  const [password, setPassword] = useState()
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    remember: false,
  });
  const [error, setError] = useState();
  const queryParameters = new URLSearchParams(window.location.search)
  const refer_code = queryParameters.get("refer_code")

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
  localStorage.setItem('userType', 1)

  const onFinish = (values) => {
    setIsLoading(true);
    if (refer_code !== null) {
      values.refer_code = refer_code
      localStorage.setItem('userType', 1)
    }
    values.role = 1
    setEmail(values.email)
    values.full_name = titleCase(values.full_name)
    SignIn(ADD_USER_API, values).then((res) => {
      setError()
      if (res.status === 201) {
        if (values.remember === true) {
          document.cookie = "email=" + values.email
          document.cookie = "password=" + values.password
        }
        else {
          document.cookie = "email=" + values.email + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
          document.cookie = "password=" + values.password + '=;  Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        }
        setIsLoading(false)
        navigate('/otp-verify', {
          state: {
            id: { email: values.email , token:res?.data?.token }
          }
        })

      }
    }).catch((e) => {
      setError(e.response.data)
      setIsLoading(false)
    })
  };

  useEffect(() => {
    setError("")
  }, [emailValidation, password])

  return (
    <AuthCard
      link="/"
      linkText={"Sign In"}
      subtitle="If you alredy have an account, click here to "
      page="Create Account"
      step="01"
      loginPage={false}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Fullname" name="full_name"
          rules={[{ required: true, message: 'Please input your Fullname', whitespace: true }]}>
          <Input placeholder="Enter your fullname" />
        </Form.Item>
        {<div className="error-msg">{error?.full_name}</div>}

        <Form.Item
          label="Email Address/Phone Number"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not a valid Email',
              validateTrigger: 'onBlur',
            },
            { required: true, message: '', whitespace: true },
            
            ({ getFieldValue }) => ({
              validator(_, value) {
                setEmailValidation(value)
                const isPhoneNumber = /^\d+$/.test(value);
                if (!getFieldValue('email') && !isPhoneNumber) {
                  return Promise.reject(
                    'Please input your Email Address/Phone Number'
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="Email Address/Phone Number" />
        </Form.Item>
        {<div className="error-msg">{error?.email}</div>}

        <Form.Item

          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password',
            },
            ({ getFieldValue, validate }) => ({
              validator(_, value) {
                setPassword(value)
                if (value && value.length < 8) {
                  return Promise.reject("Password should be equal to or more than 8 characters")
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>
        {<div className="error-msg">{error?.password}</div>}

        <Form.Item label="Confirm Password" name="confirm_password"
          rules={[
            {
              required: true,
              message: 'Please confirm your password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Your password and confirm password do not match'));
              },
            }),
          ]}>
          <Input.Password placeholder="Confirm password" />
        </Form.Item>
        {<div className="error-msg">{error?.confirm_password}</div>}
        <Button className="auth-btn login--btn" type="primary" loading={isLoading} onClick={() => form.submit()}>
          {" "}
          Create Account{" "}
        </Button>
      </Form>


      {/* <Link to="/exam-type"> */}

      {/* </Link> */}
    </AuthCard>
  );
};

export default CreateAccount;
