import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getRequest, postAuthRequest, postRequest } from "../../../services";
import { EXAM_BODY, GET_EXAM_TYPES, POST_USER_CATEGORY, TRANSACTION, UPDATE_EXAM_TYPE } from "../../../services/ApiConstant";
import { message } from "antd";

const ExamType = () => {

  const navigate = useNavigate();
  const [error, setError] = useState()
  const [messageApi, contextHolder] = message.useMessage()
  const [examTypes, setExamTypes] = useState([]);
  const [currentId,setCurrentId] =useState()

  useEffect(()=>{
    getRequest(GET_EXAM_TYPES).then((res)=>{
      setExamTypes(res?.data);
    })
  },[])

  const updateExamType = (id) => {
    setCurrentId(id)
    postRequest(POST_USER_CATEGORY+`?category_id=${id}`).then((res)=>{
      payment(id)
    }).catch((e)=>{
      setError(e.response.data.error[0])
    })

  }

  const payment = (id) => {
    postAuthRequest(TRANSACTION, { category_id: id }).then((res) => {
      res.data.message != undefined && PaymentInfo();
      window.location.href = res.data.result.data.authorization_url;
    }
    ).catch((e) => {
    })
  }

  const PaymentInfo = () => {
    // messageApi.info('Copied');
    messageApi.open({
      type: 'success',
      content: 'Subscribed to plan',
    });
  };

  return [
    <AuthCard link="/" linkText={""} subtitle=""  page="Choose Exam Type"
    step="02">
      <br />
      {contextHolder}
      <p className="subtitle">
        Choose Exam Type you wish to register for from the underlisted Category
      </p>
      {examTypes?.map(({ name, description,icon,id }, index) => (
        <><div className="exam-card">
          <img src={icon} className="icon" />
          <div className="row-between">
          <div className="pr-20">
            <div className="title">{name}</div>
            <div className="desc">{description}..</div>
          </div>
          <div className="row-center pr-10" onClick={()=> updateExamType(id)}>
            <AiOutlineArrowRight size={"24px"} color="#2F327D" />
          </div>
          </div>
        </div>
        {id==currentId && <div className="error-msg">{error}</div>}
        </>
      ))}
       
    </AuthCard>,
    <div className="footer-text">
      NOTE: We're excited to offer you a 7-day free trial. You'll have full
      access to the exam of your choice during this time, completely free of
      charge. After the trial period, your account will be automatically billed
      for the subscription fee. You can cancel your subscription within the
      period of this 7 days.
    </div>,
  ];
};

export default ExamType;
