import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const SATInstruction = () => {

  const [exam,setExam]=useState( localStorage.getItem("examName"))

  const examPage = () =>{
    if (exam ==="Reading" || exam==="Writing And Language"){
      navigate("/exam/sat-reading-test")
    }
    
    if (exam == "General Mathematics"){
      navigate("/exam/sat-math-test")
    }
    

  }

  const navigate = useNavigate();
    return (
      <>
        <div className="instruction-sec">
          <div className="instruction-wrap">
            <h2>The SAT</h2>
            <h2>Simulator Practice Test</h2>
            <p><strong>Simulator test is</strong>  one of the best ways to get ready for the SAT</p>
            <button className="start-btn" onClick={()=>{ examPage()}}>START TEST</button>
          </div>
        </div>
      </>
    );
  };
  
  export default SATInstruction;