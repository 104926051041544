import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { GET_TESTPAPER_BY_SUBJECT_ID, GET_USER_EXAM_DETAILS } from "../../../services/ApiConstant";
import { getUserData } from "../../../services";

const ExamSimulation = () => {
  const navigate = useNavigate();
  const [subject, setSubject] = useState([])
  const [year, setYear] = useState([])
  const [yearData, setYearData] = useState()
  const [selectedSub, setSelectedSub] = useState()
  const [selectedYear, setSelectedYear] = useState(null)
  const [avialbleYear, setAvailableYear] = useState([])
  const exam=localStorage.getItem("exam")
  let y = []
  let examID =''

  useEffect(() => {
     examID = localStorage.getItem("examID")
    getUserData(GET_USER_EXAM_DETAILS + `/?exam_id=${examID}`).then((res) => {
      setSubject(res.data)
      res.data?.map((i) => {
        y.push(i.exam_subject_id)
      })
      setYear(y.join())
    });
  }, [])

  useEffect(() => {
    if(year.length>0){
    getUserData(GET_TESTPAPER_BY_SUBJECT_ID + `?exam_subjects_ids=${year}`).then((res) => {
      setYearData(res.data)
    });}
  }, [year])

  const onSubjectSelect = (e) => {
    setSelectedSub(e.target.value)
    localStorage.setItem("ExamTime", subject?.filter((i) => (i.subject_name===e.target.value))[0].total_minutes)
    setAvailableYear(yearData?.filter((i) => (i.subject_name === e.target.value)))
  }

  const onYearSelect = (e) => { 
    setSelectedYear(e.target.value)
  }

  const onStartExam = () => {
    if (selectedYear !== undefined) {
      localStorage.setItem("testPaperID",selectedYear)
      localStorage.setItem("examName", yearData?.filter((i)=>i.testpaper_id==selectedYear)[0].subject_name)
      if(exam=="SAT"){
        navigate("/exam/sat-instruction")
        localStorage.setItem("isCalculator", yearData?.filter((i)=>i.testpaper_id==selectedYear)[0].is_calculator)
      }
      else{
        navigate("/exam/exam-question")
      }
    }
  }

  return (
    <>
      <div className="jabm-sec">
        <div className="jabm-container">
          < div className="quSelect-panel-wrap">
            <div className="quSelect-panel">
              <h3>SELECT SUBJECT</h3>
              <div className="radio-btn-wrap">
                {
                  subject?.map((i) => (
                    <div className="btn-box">
                      <input type="radio" id={i.subject_name} name="fav_language" value={i.subject_name} onChange={(e) => onSubjectSelect(e)} />
                      <label htmlFor={i.subject_name}>{i.subject_name}</label>
                    </div>

                  ))
                }
              </div>
            </div>

            <div className="quSelect-panel">
              {avialbleYear?.length > 0 && <div>
                <h3>SELECT YEAR</h3>
                <div className="radio-btn-wrap">
                  {
                    avialbleYear?.map((i) => (
                      <div className="btn-box">
                        <input type="radio" id={i.testpaper_id} name="subject" value={i.testpaper_id} onChange={(e) => onYearSelect(e)} />
                        <label htmlFor={i.testpaper_id}>{i.year}</label>
                      </div>
                    ))
                  }
                </div>
              </div>}
              {<div className="qucomm-btn-wrap">
                <button className="btn" disabled={!(selectedYear!==null)} onClick={onStartExam}>Start exam</button>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExamSimulation;
