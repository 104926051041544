import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../services";
import { GET_DISTINCT_YEAR_PAPER, GET_USER_EXAM_DETAILS } from "../../services/ApiConstant";

const JAMBSimulation = () => {
  const [error, setError] = useState()
  const navigate = useNavigate();
  const proceed = () => {
    if (yearSelected !== undefined) {
      localStorage.setItem('yearSelected', yearSelected);
      localStorage.setItem("selectedTestpaper", selectedSubject)
      // navigate("/exam/instruction")
      navigate("/exam/instruction", {
        state: {
          id: { examType: "JAMB" }
        }
      })
    }
    else {
      setError("please select year")
    }
  }

  const [subjects, setSubject] = useState()
  const [year, setYear] = useState([])
  const [yearSelected, setYearSelected] = useState()
  const [bunchOfTestpaper, setBunchOfTestPaper] = useState([])
  const [selectedSubject,setSelectedSubject]=useState([])

  useEffect(() => {
    let examID = localStorage.getItem("examID")
    getUserData(GET_DISTINCT_YEAR_PAPER + `?exam_id=${examID}`).then((res) => {
      setBunchOfTestPaper(res.data)
      let array = []
      res.data?.map((i) => {
        !array.includes(i.year) && setYear(year => [...year, i.year])
        array.push(i.year)
      })
    });
  }, [])

  useEffect(() => {
    let examID = localStorage.getItem("examID")
    getUserData(GET_USER_EXAM_DETAILS + `/?exam_id=${examID}`).then((res) => {
      setSubject(res.data)
      if (res.data.length === 0) {
        setError("No TestPaper available for this Subject")
      }
    });
  }, [])

  useEffect(() => {
    setError('')
  }, [yearSelected])

  return (
    <>
      <div className="jabm-sec d-flex align-items-center justify-content-center">
        <div className="jabm-wrap text-center">
          <h1 className="quttl">JAMB CBT Simulator</h1>
          <div className="quTextboxa">
            <p className="qupara">Underlisted are you subjects for the JAMB CBT simulator mode.
              This is a close replica of the actual JAMB examination.</p>
          </div>
          <div className="radio-btn-wrap align-items-center justify-content-center pb-5">

            {subjects?.map((i) =>
            (<div className="btn-box">
              <input type="checkbox" id={i.exam_subject_id} name={i.subject_name} value={i.exam_subject_id} onChange={()=> selectedSubject.includes(i.exam_subject_id)?setSelectedSubject(selectedSubject?.filter(item=>item !== i.exam_subject_id )) : setSelectedSubject([...selectedSubject,i.exam_subject_id])} />
              <label htmlFor={i.exam_subject_id}>{i.subject_name}</label>
            </div>))
            }

          </div>
          {selectedSubject?.length == 4   && <>
            <h3 className="pb-4">SELECT YEAR</h3>
            <div className="radio-btn-wrap pb-4 justify-content-center">
              {
                year?.map((i) => (
                  <div className="btn-box">
                    <input type="radio" id={i} name="year" value={i} onClick={(e) => setYearSelected(e.target.value)} />
                    <label htmlFor={i}>{i}</label>
                  </div>
                ))
              }
            </div>
          </>}
          {<div className="error-msg">{error}</div>}
          {<div className="qucomm-btn-wrap" onClick={() => proceed()}>
            <button disabled={!(yearSelected !== undefined)} className="btn">Proceed</button>
          </div>}
        </div>
      </div>
    </>
  );
};

export default JAMBSimulation;