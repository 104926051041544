import axios from "axios";
const baseUrl = "https://api.excelmind.org/api";

// const tokenCheck=((e)=>{
//   if(e.response.status==401)
//   {
//     localStorage.removeItem("token")
//     window.location="/"
//   }

// })

export const getRequest = async (endpoint) => {
  const response = await axios
    .get(baseUrl + endpoint)
  return response;
};

export const getAuthRequest = async (endpoint) => {
  const response = await axios
    .get(baseUrl+"/auth" + endpoint,{ 
      headers: { 
        'Authorization': 'Token '+localStorage.getItem('token')
      },
  })
  return response;
};

export const getUserData = async (endpoint) => {
  const response = await axios
    .get(baseUrl + endpoint,{ 
      headers: { 
        'Authorization': 'Token '+localStorage.getItem('token')
      },
  })
  return response;
};


export const postRequest = async (endpoint, data) => {
  const response = axios
    .post(baseUrl + endpoint, data,{ 
      headers: { 
        'Authorization': 'Token '+localStorage.getItem('token')
      },
  })
  return response;
};

// export const forget = async (endpoint, data) => {
//   const response = axios
//     .post(baseUrl + endpoint, data,{ 
//       headers: { 
//         'Authorization': 'Token '+localStorage.getItem('token')
//       },
//   })
//   return response;
// };


export const postAuthRequest = async (endpoint, data,header) => {
  const response = axios
    .post(baseUrl+"/auth" + endpoint, data,{ 
      headers: { 
        'Authorization': 'Token '+localStorage.getItem('token')
      },
  })
  return response;
}; 

export const putAuthRequest = async (endpoint, data,header) => {
  const response = axios
    .put(baseUrl+"/auth" + endpoint, data,{ 
      headers: header?header :{ 
        'Access-Control-Allow-Origin': 'cross-origin', 
        'Accept': 'application/json, text/plain, */*', 
        'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Safari/537.36', 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+localStorage.getItem('token')
      },
  })

  return response;
};

export const passwordUpdate = async (endpoint, data) => {
  const response = axios
    .post(baseUrl+"/auth" + endpoint, data,{ 
      headers:{ 
        'Authorization': 'Token '+localStorage.getItem('token')
      },
  })

  return response;
}; 

export const Login = async (endpoint, data,header) => {
    const response = axios
      .post(baseUrl + endpoint,{},{ 
        headers: header?header :{ 
          'Authorization': 'Basic ' + btoa(data.email + ":" + data.password)
        },
  })
  return response;
};

export const SignIn = async (endpoint, data,header) => {
  const response = axios
    .post(baseUrl + endpoint,data,{ 
      headers: header?header :{ 
        'Accept': 'application/json', 
        'Content-Type': 'application/json',
      },
})
return response;
};

export const Logout = async (endpoint, data) => {
  const response = axios
    .post(baseUrl + endpoint, data,{ 
      headers: { 
        'Authorization': 'Token '+localStorage.getItem('token')
      },
  })

  return response;
};
