import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Components/Header";
import Sidebar from "./Components/Sidebar";

const Layout = ({updatedData,setIsLoggedIn, userProfileImage}) => {
  return (
    <div className="site-height-wrap" style={{width:'100%',display:'flex',flexDirection:'column'}}>
      <Header updatedData={updatedData} userProfileImage={userProfileImage}/>
      <div className="real-home stickyheadGap">
        <Sidebar setIsLoggedIn={setIsLoggedIn} />
        <div className="outlet">
        <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;