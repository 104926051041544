import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tabs } from 'antd';
import { MdKeyboardArrowLeft } from "react-icons/md";
import Header from "../../Components/Header";
import GeneralSettings from "./GeneralSettings";
import ChangePassword from "./ChangePassword";
import PaymentMethod from "./PaymentDetails";

const ProfileSetting = ({ step, page,fetchingUpdatingData,fetchingUserImage }) => {
  const navigate = useNavigate();

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: '1',
      label: `General Settings`,
      children: <GeneralSettings fetchingUpdatingData={fetchingUpdatingData} fetchingUserImage={fetchingUserImage}/>,
    },
    {
      key: '2',
      label: `Password Settings`,
      children: <ChangePassword />,
    },
    {
      key: '3',
      label: `Payment Method`,
      children: <PaymentMethod/>,
    },
  ];

  return (
    <div className="w-100">
     <Tabs size="large" defaultActiveKey="1" items={items} onChange={onChange} />
        
    </div>
  );
};

export default ProfileSetting;
