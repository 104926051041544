import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { useLocation, useNavigate } from "react-router-dom";
import { postRequest } from "../../../services";
import { REQUEST_OTP, VERIFY_OTP } from "../../../services/ApiConstant";
import OTPInput from "react-otp-input";
import { message } from 'antd';

const OTP = () => {
    const [error, setError] = useState()
    const navigate = useNavigate();
    const { state } = useLocation();
    const [email, setEmail] = useState(state?.id.email)
    const [token, setToken] = useState(state?.id.token)
    const [forgetPassword, setForgetPassword] = useState(state?.id.forgetPassword)
    const [otp, setOtp] = useState('')
    const [minutes, setMinutes] = useState(10);
    const [seconds, setSeconds] = useState(0);
    const [userType,setUserType]=useState(localStorage.getItem("userType"))
    const [messageApi, contextHolder] = message.useMessage()
    const [resendButton,setResendButton]=useState(true)

    const verify = () => {
        let value = {}
        value.otp = otp
        value.email = email
        value.password_reset = forgetPassword
        postRequest(`/email${VERIFY_OTP}`, value).then((res) => {
            localStorage.setItem('token', token);
            if(forgetPassword){
                navigate("/create-password", {
                    state: {
                      id: { email: email , otp : otp }
                    }
                  })
            }
            else if(userType==1){
                navigate('/exam-type')
            }
            else if(userType==2){
                navigate('/amb-bank-details')
            }
            else{
                setError("Please Select type of user")
            }
            
        }).catch(() => {
            setError("OTP is incorrect")

        })
    }

    const resendOtp = () => {
        messageApi.open({
            type: 'success',
            content: 'Otp Resend',
        });
        let value = {}
        value.email = email
        postRequest(`/email${REQUEST_OTP}`, value).then((res) => {
            setMinutes(10);
            setSeconds(0);
        })
    }

    useEffect(() => {
        setUserType(localStorage.getItem("userType"))
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    setResendButton(false)
                    clearInterval(interval);
                    setError("OTP is Exprired")

                } else {
                    setResendButton(true)
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    return (
        <div>
            
            {contextHolder}

        <AuthCard
            loginPage={false}
            isHide={true}
        >
            <div className="otpmodal">
                <h2 className="text-center">Enter OTP</h2>
                <p>Please Enter 6 digit OTP sent to your email</p>
                <div className="otpmodal-wrap">

                    <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputStyle={"inputStyle"}
                        inputType="number"
                        renderInput={(props) => <input {...props} />}
                    />

                    {<div className="error-msg">{error}</div>}
                    <div className="otpmodal-btn-panel pt-4 pb-4">
                        <button className="btn w-100" onClick={() => verify()}>verify OTP</button>
                    </div>
                    Don't get code?  <button className="otpResend" disabled={resendButton} onClick={() => resendOtp()} style={{cursor:'pointer'}} >Resend</button>
                    <br />
                    <p>
                    OTP Expires in: <span className="otptimer">{minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                            </span></p>
                </div>

            </div>

        </AuthCard>
        </div>
    );
};

export default OTP;
