import React from "react";
import { useNavigate } from "react-router-dom";
import AuthCard from "../../../Components/AuthCard";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Button, Form,Input } from "antd";
import { Link } from "react-router-dom";
import { REQUEST_OTP } from "../../../services/ApiConstant";
import { postRequest } from "../../../services";

const ForgotPassword = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    
    const onFinish = (values) => {
      let value = {}
      value.email = values.email
        postRequest(`/email${REQUEST_OTP}`,value ).then((res) => {          
            navigate('/otp-verify', {
              state: {
                id: { email: values.email , forgetPassword:true }
              }
            })
        })
    }

  return (
    <AuthCard isHide={true}>
<div className="reduced-t-gap">
  
<p className="title">Reset Password</p>

<p className="subtitle">
  Please enter the email address you used to create an account. We will
  send you a link to reset your password.
</p>
<Form form={form} layout="vertical"onFinish={onFinish}>
<Form.Item
    label="Email Address"
    name="email"
    rules={[
      { required: true, message: 'Please input your email address!' },
      { type: 'email', message: 'Please enter a valid email address!' }
    ]}
  >
    <Input required placeholder="Enter your email" />
  </Form.Item>
</Form>

{/* <Link to="/create-password">
  <button className="auth-btn" type="button">
    Send Link{" "}
  </button>
</Link> */}
<Button className="auth-btn login--btn" type="primary"  onClick={() => form.submit()}>
    {" "}
    Send Link{" "}
  </Button>
</div>
    </AuthCard>
  );
};

export default ForgotPassword;
