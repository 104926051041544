import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { GET_ANSWERS_BY_QUESTION_IDS, GET_USER_TESTPAPER_STATUS, IELTS_GET_QUESTION_BY_TESTPAPER_IDS } from '../../services/ApiConstant';
import { getUserData, postRequest } from '../../services';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from "antd";
import Question from '../../assets/common/Question';
import Options from '../../assets/common/Option';


const IELTSTASK1 = () => {

  const [allData, setAllData] = useState([])
  const [taskNo, setTaskNo] = useState(0)
  const testpaperId = localStorage.getItem("testPaperID")
  const [group, setGroup] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  let examID = localStorage.getItem("examID")
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timer, setTimer] = useState();
  const [timerStatus, setTimetStatus] = useState(false);
  const [timerReset, SetTimerRest] = useState(true);
  const [result, setResult] = useState();
  const [backModalOpen, setBackModalOpen] = useState(false)
  const [takenTime, setTakenTime] = useState()

  const [nav, setNav] = useState(true)
  let time = localStorage.getItem("ExamTime");


  useEffect(() => {
    let array = []
    getUserData(IELTS_GET_QUESTION_BY_TESTPAPER_IDS + `?testpaper_id=${testpaperId}`).then((res) => {
      res.data?.map((i) => {
        i.questions?.map((i) => {
          return array.push({ "question_id": i.question, "answer": "", "attempted": false })
        })
        setAllData(res.data)
        setGroup(array)
        setTimetStatus(true);
      });
    })
  }, [])

  const gettingAns = (e, question_id) => {
    group?.map((i) => {
      if (i.question_id === question_id) {
        i.answer = e.target.value
        i.attempted = true
      }
    })
  }

  const ansChecking = (ans, question) => {
    let status = false
    group?.map((i) => {
      if (i.question_id == question) {
        status = i.answer == ans
      }
    })
    return status
  }


  const getResult = () => {
    if (group.length > 0 && testpaperId) {
      postRequest(GET_ANSWERS_BY_QUESTION_IDS + `?testpaper_id=${testpaperId}&exam_id=${examID}`, group).then((res) => {
        setResult(res.data)
        setNav(true)
      });
      postRequest(GET_USER_TESTPAPER_STATUS + `?testpaper_id=${testpaperId}`).then((res) => {
      });
    }
  }

  const showConfirm = () => {
    if (result !== undefined) {
      localStorage.setItem("result", JSON.stringify(result))
      localStorage.setItem("takenTime", timer)
      localStorage.setItem("AnsScreenOf", "ielts");
      navigate("/exam/result")
    }
  };

  useEffect(() => {
    if (nav) {
      showConfirm()
    }
  }, [nav])

  let x = null;
  const Timer = (time) => {
    time = time * 60
    let seconds = time % 60;
    let minutes = Math.floor((time / 60) % 60);
    let hours = Math.floor(time / 3600);
    x = setInterval(function () {
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(x);
            if (timerReset) {
              group.length !== 0 && submitExam();
            }
            return;
          }
          hours -= 1;
          minutes = 59;
        } else {
          minutes -= 1;
        }
        seconds = 59;
      } else {
        seconds -= 1;
      }
      function n(n) {
        return n > 9 ? "" + n : "0" + n;
      }
      setTimer(`${n(hours)}:${n(minutes)}:${n(seconds)}`);
    }, 1000);
  };

  useEffect(() => {
    timerStatus && Timer(time);
  }, [timerStatus]);

  const nextQuestion = () => {
    setTaskNo(taskNo + 1)
    if (currentQuestionIndex < allData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    }
  }

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1)
    }
    setTaskNo(taskNo - 1)
  }

  const submitExam = () => {
    postRequest(
      GET_ANSWERS_BY_QUESTION_IDS + `?testpaper_id=${testpaperId}&exam_id=${examID}`,
      group
    ).then((res) => {
      setResult(res.data);
    }).catch((err) => {
    })
  };


  const handleOk = () => {
    setIsModalOpen(false);
    clearInterval(x);
    SetTimerRest(false);
    submitExam();
    // getResult();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const backHandleOk = () => {
    navigate(-1)
  }

  const backHandleCancel = () => {
    setBackModalOpen(false)
  }

  const questionNumberButton = (index) => {
    setTaskNo(index)
    setCurrentQuestionIndex(index)
  }

  function msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    return hours + ":" + minutes + ":" + seconds
  }

  window.location.hash = "no-back-button";
  window.onhashchange = function () {
      window.location.hash = "no-back-button";
  }

  useEffect(() => {
    if (timer !== undefined) {
      const [hours, minutes, seconds] = timer.split(':')?.map(Number);
      const milliSecondsTimeWeHave = time * 60 * 1000;
      const milliSecondsTimeWeTaken = ((hours * 60 + minutes) * 60 + seconds) * 1000;
      const fakeTime = milliSecondsTimeWeHave - milliSecondsTimeWeTaken;
      const realTime = msToTime(fakeTime);
      setTakenTime(realTime);

      if (result !== undefined) {
        localStorage.setItem("result", JSON.stringify(result));
        localStorage.setItem("takenTime", realTime);
        localStorage.setItem("AnsScreenOf", "ielts");
        navigate("/exam/result")
      }
    }
  }, [timer, result]);

  return (
    <>

      <div className="ielts-sim-body">
        <Modal
          open={backModalOpen}
          onCancel={backHandleCancel}
          width={316}
          footer={[
            <Button key="back" onClick={backHandleCancel}>
              No, I don't want
            </Button>,
            <Button key="submit" type="primary" onClick={backHandleOk}>
              Yes, I want
            </Button>,
          ]}
        >
          <div>
            <p style={{ color: "#002978" }}>
              <img src="../images/alert.png" /> Are you sure?
            </p>
            <br />
            <p className="pb-3">
              Are you sure you want to leave your exam?
            </p>
          </div>
        </Modal>
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          width={316}
          footer={[
            <Button key="back" onClick={handleCancel}>
              No, don't submit
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              Yes, Submit
            </Button>,
          ]}
        >
          <div>
            <p style={{ color: "#002978" }}>
              <img src="../images/alert.png" /> Are you sure?
            </p>
            <br />
            <p className="pb-3">
              Are you sure you want to submit your exam? This action cannot be
              undone. Once you submit, you will not be able to go back and
              change your answers.
            </p>
          </div>
        </Modal>
        <div className="ielts-sim-header">
          <div className="sim-ttl">
            <h3>IELTS Simulator</h3>
          </div>
          <div className="sim-time">
            {timer ? <p><span>{timer?.slice(0, 2)}</span>:<span>{timer?.slice(3, 5)}</span>:<span>{timer?.slice(6, 8)}</span></p> : <p><span>00</span>:<span>00</span>:<span>00</span></p>}
          </div>
          <div className="sim-btn-wrap">
            <button className="btn" onClick={() => setBackModalOpen(true)}>Back</button>
            <button className="btn" onClick={() => setIsModalOpen(true)}>Submit</button>
          </div>
        </div>
        <div className="sim-main-ttl">
          <h3>{localStorage.getItem("examName")} Test - {localStorage.getItem("SelectedYear")}</h3>
        </div>
        <div className="sim-main-body">
          <Row>
            <Col span={12} className='bor-solid-rt'>
              <div className="task-box " dangerouslySetInnerHTML={{ __html: allData[taskNo]?.paragraph.replace(/\n/g, "<br />") }}></div>
            </Col>
            <Col span={12}>
              <div className="task-box ds-mrl">

                {/* <h2>Question 8 - 13</h2> */}
                <h2>Question 1-{allData[taskNo]?.questions.length}</h2>
                <p>Do the following statments agree with the information given in the text?</p>
                <div className="bg-table mb-4">
                </div>
                <ol className='qui-list'>
                  {allData[taskNo]?.questions?.map((i, index) => {
                    return <li key={index} className='d-flex'>
                      <small >{index + 1}.</small>
                      <select className="d-flex" style={{alignItems: "center"}} name='selectAns' onChange={(e) => gettingAns(e, i.question)}>
                        <option></option>
                        {
                          i?.answer?.map((j, index) => (
                            <option key={index} value={j?.ans} selected={ansChecking(j?.ans, i.question)} ><Options option={j?.ans}/></option>
                          ))
                        }
                      </select>
                      <span><Question question={i.question_details.question_text}/></span>
                    </li>
                  })}
                </ol>
                <div className="back-next-btn">
                  <button className='btn back-btn' onClick={previousQuestion} disabled={currentQuestionIndex === 0}>Back</button>
                  <button className='btn' onClick={nextQuestion} disabled={currentQuestionIndex === allData.length - 1}>Next</button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="task-menu-bar">
          {allData?.map((i, index) => (<button key={index} className={index === taskNo && 'active'} onClick={() => questionNumberButton(index)}>Task {index + 1}</button>))}
        </div>
      </div>
    </>
  );
};

export default IELTSTASK1;
