import { Select } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, postAuthRequest, postRequest } from "../../services";
import { GET_ALL_DEPARTMENT_WISE_SUBJECTS, GET_OTHER_EXAM_DETAILS_EXAM, GET_SUBJECT_BY_EXAM, TRANSACTION, USEREXAM } from "../../services/ApiConstant";
import { message } from 'antd';


const SubcribeExam = () => {
    const [messageApi, contextHolder] = message.useMessage()

    const { state } = useLocation();
    const navigate = useNavigate();
    const [selectedExam, setSelectedExam] = useState(state?.id?.exam.name)
    const [examId, setExamId] = useState(state.id?.exam?.id)
    const [isUtme, setIsUtem] = useState(state.id?.exam?.isUtme)
    const [subject, setSubject] = useState([])
    const [maxSubject, setMaxSubject] = useState([])
    const [selectedSubject, setSelectedSubject] = useState([])
    const [error, setError] = useState()
    const [require, setRequire] = useState([])
    const [examDetails, setExamDetails] = useState([])
    const [department, setDepartment] = useState([])
    useEffect(() => {
        !isUtme && getRequest(GET_SUBJECT_BY_EXAM + state?.id.exam.mainId).then((res) => {
            // setAllExam(res.data)
            setSelectedSubject([])
            setSubject(res.data?.filter((item) => (item.exam === state?.id.exam.name))[0])
        })
        setError("")
        getRequest(GET_OTHER_EXAM_DETAILS_EXAM + "?exam_id=" + state?.id.exam.id).then((res) => {
            setExamDetails(res.data[0])
        })
        isUtme && getRequest(GET_ALL_DEPARTMENT_WISE_SUBJECTS + "?exam_id=" + state?.id.exam.id).then((res) => {
            setDepartment(res.data)
        })
    }, []);

    useEffect(() => {

        setError("")
        subject?.exam_subjects?.map((i) => {
            if (i.required) {
                setSelectedSubject(selectedSubject => [...selectedSubject, i.id])
                setRequire(require => [...require, i.id])
            }
            const obj = { value: i.id, label: i.name, required: i.required }
            return obj
        })
    }, [subject]);

    const check = (e) => {
        if (selectedSubject.length < subject.max_subject || isUtme) {
            if (!selectedSubject.includes(e)) {
                setSelectedSubject([...selectedSubject, e])
            }
        }
        else {
            setError(`please select only ${maxSubject} subjects `)
        }
    }

    const deleteSub = (sub) => {
        setError()
        if (require.includes(sub)) {
            setError("required subject can not be deleted ")
        }
        else {
            setSelectedSubject(selectedSubject?.filter((i) => (i !== sub)))
        }
    }

    const onSubmit = async(e) => {
        if(selectedSubject.length===0){
            setError("Choose Subject First")
        }
        let values = {}
        values.exams_subjects = selectedSubject.join()
        values.exam_id = examId
        postRequest(USEREXAM, values).then((res) => {
            payment()
            info()
        }
        ).catch((e) => {
            setError(e.response.data.error[0])
        })
    }
      
      

    const payment = () => {
        postAuthRequest(TRANSACTION, { exam_id: examId }).then((res) => {
            res.data.message != undefined && PaymentInfo();
            window.location.href = res.data.result.data.authorization_url;
            // info()
        }
        ).catch((e) => {
        })
    }

    const PaymentInfo = () => {
        messageApi.open({
            type: 'success',
            content: 'Subscribed to plan',
            onClose: () => {
                navigate('/dashboard/home');
              }
        });
    };

    const info = () => {
        messageApi.open({
            type: 'success',
            content: 'Exam Added ',
        });
    };

    const selectedDepartment = (e) => {
        setSelectedSubject([])
        setSubject(department?.filter((i) => (i.department == e))[0])
    }
    
    useEffect(()=>{
        setMaxSubject(subject?.max_subject)
    },[subject])

    return (
        <>
            <div className="subcribe-exam-wrap">
                {contextHolder}
                <div className="recommend-exam">
                    <div className="image">
                        <img src={examDetails?.icon} />
                    </div>
                    <div className="info-recommend">
                        <div className="r-exam-name">
                            <p>Year {examDetails?.first_year}-{examDetails?.last_year}</p>
                            <h2>{examDetails?.exam}</h2>
                            <p style={{ color: '#2F327D', fontSize: 13 }}>₦{examDetails?.price}</p>
                        </div>
                        <div className="info-recommend-rt">
                            {isUtme ? <>

                                <div className="select-btn-wrap">
                                    <Select className="select-subject" onSelect={(e) => { selectedDepartment(e) }}>
                                        {department?.map((i) => (<Select.Option value={i?.department}>{i?.department}</Select.Option>))}
                                    </Select>
                                    <Select className="select-subject" onSelect={(e) => { check(e) }}>
                                        {subject?.exam_subjects?.map((i) => (<Select.Option value={i?.id}>{i?.subject_name}</Select.Option>))}
                                    </Select>
                                    <br />
                                    {<div className="error-msg">{error}</div>}
                                    <div className="subjectWrap">
                                        {selectedSubject?.map((sub) => (
                                            <div className="subject">
                                                {subject.exam_subjects.map((i) => { if (i.id === sub) { return i.subject_name } })}
                                                &nbsp;&nbsp;&nbsp;
                                                <AiOutlineClose color="#2C2C2C" onClick={() => { deleteSub(sub) }} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>

                                : <div className="select-btn-wrap">
                                    <Select className="select-subject" onSelect={(e) => { check(e) }}>
                                        {subject?.exam_subjects?.map((i) => (<Select.Option value={i?.id}>{i?.name}</Select.Option>))}
                                    </Select>
                                    <br />
                                    {<div className="error-msg">{error}</div>}
                                    <div className="subjectWrap">
                                        {selectedSubject?.map((sub) => (
                                            <div className="subject">
                                                
                                                {subject.exam_subjects.map((i) => { if (i.id === sub) { return i.name } })}
                                                &nbsp;&nbsp;&nbsp;
                                                <AiOutlineClose color="#2C2C2C" onClick={() => { deleteSub(sub) }} />
                                            </div>
                                        ))}
                                    </div>
                                </div>}
                            <div className="btn-panel-box">
                                <button className="auth-btn" type="button" onClick={() => onSubmit()} disabled={selectedSubject.length===0}>
                                    {" "}
                                    Done{" "}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default SubcribeExam;