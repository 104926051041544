import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "antd";
import { Form, Checkbox, Button } from "antd";
import { Login, postRequest } from "../../../services";
import { LOGIN_API, REQUEST_OTP } from "../../../services/ApiConstant";

const Signin = ({ isLoggedIn, setIsLoggedIn }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [error, setError] = useState("");
  const [role, setRole] = useState(localStorage.getItem("userType"));
  const [CreateAccountLink, setCreateAccountLink] = useState();

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  useEffect(() => {
    let role = "";
    role = localStorage.getItem("userType");
    setEmail(getCookie("email"));
    setPass(getCookie("password"));

    if (role == 1) {
      setCreateAccountLink("/create-account");
    }
    if (role == 2) {
      setCreateAccountLink("/amb-create-account");
    }
  }, []);

  useEffect(() => {
    handleLoginStatusChange();
  }, [isLoggedIn]);

  const handleLoginStatusChange = () => {
    const isLoggedInValue = localStorage.getItem("token");
    // setIsLoggedIn(isLoggedInValue);
  };

  const sendOtp = (email) => {
    let value = {}
    value.email = email
    postRequest(`/email${REQUEST_OTP}`, value).then((res) => {
    })
}

  const onFinish = (values) => {
    setIsLoading(true);
    console.log(values)
    Login(LOGIN_API, values)
      .then((res) => {
        if (res?.data?.user?.role != role) {
          setError(`This email doesn't belongs to this user`);
          setIsLoading(false);
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        if (values.remember === true) {
          document.cookie = "email=" + values.email;
          document.cookie = "password=" + values.password;
        } else {
          document.cookie =
            "email=" +
            values.email +
            "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          document.cookie =
            "password=" +
            values.password +
            "=;  Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }

        // localStorage.setItem('userType',res?.data?.id)
        if (res?.data?.is_verified === "Y") {
          localStorage.setItem("token", res?.data?.token);
          if (res?.data.user.role == 1) {
            setIsLoggedIn(true);
            navigate("/dashboard");
          }
          if (res?.data.user.role == 2) {
            if(res?.data.account_number==null){
              setIsLoggedIn(false);
              navigate("/amb-bank-details");
            }else{
              setIsLoggedIn(true);
              navigate("/ambassodor/ambassodor-overview");
            } 
          }
        }
        else if (res?.data?.is_verified === "N") {
          if (res?.data.user.role == 1) {
            localStorage.setItem('userType', 1)
            sendOtp(res?.data?.user.email)
            navigate('/otp-verify', {
              state: {
                id: { email: res?.data?.user.email , token:res?.data?.token }
              }
            })
          }
          else if (res?.data.user.role == 2) {
            localStorage.setItem('userType', 2)
            sendOtp(res?.data?.user.email)
            navigate('/otp-verify', {
              state: {
                id: { email: res?.data?.user.email , token:res?.data?.token }
              }
            })
          }
        }

      })
      .catch((e) => {
        setIsLoading(false);
        setError(e?.response?.data?.detail);
      });
  };

  return (
    <AuthCard
      link={CreateAccountLink}
      linkText={"Create an Account"}
      subtitle="Don’t have an account? click here to"
      isHide={true}
      loginPage={false}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email!",
            },
          ]}
        >
          <Input placeholder="Enter your email" defaultValue={email} />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>

        <div className="error-msg d-flex">{error}</div>
        <Form.Item className="height-unset">
          <div className="row-between">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Link to="/forgot-password">
              <div className="login-form-forgot">Forgot password</div>
            </Link>
          </div>
        </Form.Item>
        <Button
          className="auth-btn login--btn"
          type="primary"
          onClick={() => form.submit()}
          loading={isLoading}
        >
          {" "}
          Log in{" "}
        </Button>
      </Form>
    </AuthCard>
  );
};

export default Signin;
