import { useEffect, useState } from "react";
import { getUserData, postRequest } from "../../services";
import {
  GET_JAMB_EXAM_QUESTIONS,
  GET_USER_EXAM_DETAILS,
  GET_USER_TESTPAPER_STATUS,
  JAMB_GET_ANSWERS_BY_QUESTION_IDS,
} from "../../services/ApiConstant";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import Calculator from "../../assets/common/Calculator";
import Question from "../../assets/common/Question";
import Options from "../../assets/common/Option";


const JAMBquestion = () => {
  const [subject, setSubject] = useState();
  const navigate = useNavigate();
  const [examSubjectIds, setExamSubjectIds] = useState();
  const [allQuestion, setAllQuestion] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [displayQuestion, setDisplayQuestion] = useState();
  const [group, setGroup] = useState([]);
  const [testPaperBunch, setTestPaperBunch] = useState([]);
  const [timer, setTimer] = useState();
  const [show, setShow] = useState(false);
  const [result, setResult] = useState();
  const [arrayOfQuestion, setArrayOfQuestion] = useState([]);
  const [timerStatus, setTimetStatus] = useState(false);
  const [arrayOfSubject, setArrayOfSubject] = useState([]);
  const [timerReset, SetTimerRest] = useState(true);
  const [status, SetStatus] = useState(false);
  const [calculator, setCalculator] = useState(false);
  const [error, setError] = useState();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [takenTime, setTakenTime] = useState();
  const abcd = ["A", "B", "C", "D", "E"]
  let examID = localStorage.getItem("examID");
  let time = localStorage.getItem("ExamTime");
  let selectedExamId = localStorage.getItem("selectedTestpaper")
  selectedExamId = selectedExamId.split(",")
  selectedExamId = selectedExamId.map(Number)

  useEffect(() => {
    getUserData(GET_USER_EXAM_DETAILS + `/?exam_id=${examID}`).then((res) => {
      setSubject(res.data?.filter((i) => selectedExamId.includes(i.exam_subject_id)));
      let array = [];
      res.data?.filter((i) => selectedExamId.includes(i.exam_subject_id)).map((i) => {
        !array.includes(i.exam_subject_id) &&
          setArrayOfSubject((arrayOfSubject) => [
            ...arrayOfSubject,
            i.exam_subject_id,
          ]);
        array.push(i.exam_subject_id);
      });
    });
  }, []);

  useEffect(() => {
    let yearSelected = localStorage.getItem("yearSelected");
    subject !== undefined &&
      getUserData(
        GET_JAMB_EXAM_QUESTIONS +
        `?exams_subjects=${arrayOfSubject}&paper_year=${yearSelected}`
      ).then((res) => {
        res.data.map((j) => {
          let ans = [];
          let testPaper = j.testpaper_id;
          let newobj = {};
          j.questions.map((i) => {
            ans = [
              ...ans,
              { question_id: i.question, answer: "", attempted: false },
            ];
          });
          newobj[testPaper] = ans;
          if (!testPaperBunch?.includes(testPaper)) {
            setTestPaperBunch((testPaperBunch) => [
              ...testPaperBunch,
              testPaper,
            ]);
            setGroup((group) => [...group, newobj]);
            setTimetStatus(true);
          }
        });
      });
    setExamSubjectIds(arrayOfSubject[0]);
  }, [arrayOfSubject]);

  useEffect(() => {
    setSelectedQuestion("");
    setDisplayQuestion();
    let yearSelected = localStorage.getItem("yearSelected");
    examSubjectIds !== undefined &&
      getUserData(
        GET_JAMB_EXAM_QUESTIONS +
        `?exams_subjects=${examSubjectIds}&paper_year=${yearSelected}`
      )
        .then((res) => {
          setError("");
          let ans = [];
          setAllQuestion(res.data[0]);
          setArrayOfQuestion([]);

          let array = [];
          res.data[0].questions?.map((i) => {
            if (!array.includes(i.question)) {
              setArrayOfQuestion((arrayOfQuestion) => [
                ...arrayOfQuestion,
                i.question,
              ]);
              array.push(i.question);
            }
          });
        })
        .catch((err) => {
          setError(err.response.data.error);
          setArrayOfQuestion([]);
          setAllQuestion([]);
        });
  }, [examSubjectIds]);

  useEffect(() => {
    setSelectedQuestion(arrayOfQuestion[0]);
  }, [arrayOfQuestion]);

  useEffect(() => {
    setDisplayQuestion(
      allQuestion?.questions?.filter(
        (data) => data.question === selectedQuestion
      )
    );
  }, [selectedQuestion]);

  const selectChoice = (e, subject, question) => {
    setShow(!show);
    group.map((i) => {
      i[allQuestion?.testpaper_id]?.map((i) => {
        if (i.question_id == question) {
          i.answer = e.target.value;
          i.attempted = true;
        }
      });
    });
  };

  const ansChecking = (ans, question) => {
    let status = false;
    group.map((i) => {
      i[allQuestion?.testpaper_id]?.map((i) => {
        if (i.question_id == question) {
          status = i.answer == ans;
        }
      });
    });
    return status;
  };

  const submitExam = () => {
    postRequest(
      `${JAMB_GET_ANSWERS_BY_QUESTION_IDS}?exam_id=${examID}`,
      group
    ).then((res) => {
      localStorage.setItem("testpaperbunch", testPaperBunch)
      setResult(res.data);
    });

    testPaperBunch.map((i) => {
      postRequest(GET_USER_TESTPAPER_STATUS + `?testpaper_id=${i}`).then((res) => {
      });
    })
  };

  useEffect(() => {
    timerStatus && Timer(time);
  }, [timerStatus]);

  let x = null;
  const Timer = (time) => {
    time = time * 60
    let seconds = time % 60;
    let minutes = Math.floor((time / 60) % 60);
    let hours = Math.floor(time / 3600);
    x = setInterval(function () {
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(x);
            if (timerReset) {
              group.length !== 0 && submitExam();
            }
            return;
          }
          hours -= 1;
          minutes = 59;
        } else {
          minutes -= 1;
        }
        seconds = 59;
      } else {
        seconds -= 1;
      }
      function n(n) {
        return n > 9 ? "" + n : "0" + n;
      }
      setTimer(`${n(hours)}:${n(minutes)}:${n(seconds)}`);
    }, 1000);
  };

  const nextSubject = () => {
    let i = null;
    i = arrayOfSubject.indexOf(Number(examSubjectIds));

    SetStatus(!status);
    arrayOfSubject.length - 1 > i && setExamSubjectIds(arrayOfSubject[i + 1]);
  };

  const nextQuestion = () => {
    let i = null;
    if (currentQuestionIndex < arrayOfQuestion.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    i = arrayOfQuestion.indexOf(Number(selectedQuestion));
    arrayOfQuestion.length - 1 > i &&
      setSelectedQuestion(arrayOfQuestion[i + 1]);
  };

  const backQuestion = () => {
    let i = null;
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
    i = arrayOfQuestion.indexOf(Number(selectedQuestion));
    0 < i && setSelectedQuestion(arrayOfQuestion[i - 1]);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
    SetTimerRest(false);
    clearInterval(x);
    submitExam();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  window.location.hash = "no-back-button";
  // window.location.hash = "Again-No-back-button";

  window.onhashchange = function () {
    window.location.hash = "no-back-button";
  };

  useEffect(() => {
    if (timer !== undefined) {
      const [hours, minutes, seconds] = timer.split(':').map(Number);
      const milliSecondsTimeWeHave = time * 60 * 1000;
      const milliSecondsTimeWeTaken = ((hours * 60 + minutes) * 60 + seconds) * 1000;
      const fakeTime = milliSecondsTimeWeHave - milliSecondsTimeWeTaken;
      const realTime = msToTime(fakeTime);
      setTakenTime(realTime);

      if (result !== undefined) {
        localStorage.setItem("result", JSON.stringify(result));
        localStorage.setItem("takenTime", realTime);
        navigate("/exam/jamb-result")
      }
    }
  }, [timer, result]);

  function msToTime(duration) {
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds
  }

  const handleCopyPaste = (event) => {
    event.preventDefault()
  }

  const questionNumberButton = (questionNo, Index) => {
    setSelectedQuestion(questionNo)
    setCurrentQuestionIndex(Index)
  }

  return (
    <>
      <div className="jabm-sec">
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          width={316}
          footer={[
            <Button key="back" onClick={handleCancel}>
              No, don't submit
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              Yes, Submit
            </Button>,
          ]}
        >
          <div>
            <p style={{ color: "#002978" }}>
              <img src="../images/alert.png" /> Are you sure?
            </p>
            <br />
            <p className="pb-3">
              Are you sure you want to submit your exam? This action cannot be
              undone. Once you submit, you will not be able to go back and
              change your answers.
            </p>
          </div>
        </Modal>
        <div className="jabm-container">
          <div className="pt-4">
            <div className="btnPanelWrap pb-4">
              {subject?.map((i) => (
                <button
                  className={
                    i.exam_subject_id == examSubjectIds
                      ? "btn selectedSub"
                      : "btn"
                  }
                  value={i.exam_subject_id}
                  onClick={(e) => { setExamSubjectIds(e.target.value); setCurrentQuestionIndex(0) }}
                >
                  {i.subject_name}
                </button>
              ))}
            </div>
          </div>
          {<div className="error-msg">{error}</div>}
          <div className="calculatorPanle">
            <div
              className="calculator-box"
              onClick={() => setCalculator(!calculator)}
            >
              <div className="cal-icon">
                <img src="/images/fa-solid_calculator.png" alt="" />
              </div>
              <div className="text">
                <p>Calculator</p>
              </div>
            </div>
            {calculator && <Calculator />}
            <div className="timer-box">
              <span>
                <img src="/images/timer-icon.png" alt="" />{" "}
                {timer ? timer : "00:00:00"}
              </span>
            </div>
          </div>
          <div className="quit-main-wrap pb-4">
            <p>
              <i>Choose the option that best completes the gap(s).</i>
            </p>
            <div className="exQupanel">
              {displayQuestion !== undefined && (
                <>
                  {displayQuestion[0]?.diagram && <div className="exQucusupload mb-4">
                    <label htmlFor="upload">
                      <img src={displayQuestion[0]?.diagram} alt="" />
                    </label>
                  </div>}
                  <div className="exQucusupload mb-4">
                    {displayQuestion[0]?.question_details.question_explaination}
                  </div>
                  <div className="qutext">
                    <h3 onPaste={handleCopyPaste} onCopy={handleCopyPaste} onContextMenu={handleCopyPaste}>
                      <Question question={displayQuestion[0]?.question_details?.question_text} no={currentQuestionIndex + 1}/>  
                    </h3>
                  </div>
                  <div className="quoplist">
                    {displayQuestion[0]?.question_details.question_note && <div className="exQucusupload mb-4 note-bold">
                      {displayQuestion[0]?.question_details.question_note}
                    </div>}
                    <ul className="cusradiolist">
                      {displayQuestion[0]?.answer.map(
                        (j, index) =>
                          j?.ans !== null && (
                            <li>
                              <input
                                type="radio"
                                id={j.ans}
                                name={displayQuestion[0]?.question}
                                value={j.ans}
                                onClick={(e) =>
                                  selectChoice(
                                    e,
                                    selectedQuestion[0]?.testpaper_id,
                                    displayQuestion[0]?.question
                                  )
                                }
                                checked={ansChecking(
                                  j.ans,
                                  displayQuestion[0]?.question
                                )}
                              />
                              <label htmlFor={j.ans}><Options option={j?.ans} index={index}/></label>
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          <>
            <div className="quit-btn-panel pb-4">
              <div className="back-next-side">
                <button
                  className={false ? "btn selectedSub" : "btn"}
                  onClick={() => backQuestion()}
                  disabled={currentQuestionIndex === 0}
                >
                  <img src="/images/left-arrow-icon.png" alt="" />
                  &nbsp;Back
                </button>
                <button
                  className={false ? "btn selectedSub" : "btn"}
                  onClick={() => nextQuestion()}
                  disabled={currentQuestionIndex === arrayOfQuestion.length - 1}
                >
                  Next&nbsp;
                  <img src="/images/right-arrow-icon.png" alt="" />
                </button>
              </div>
              <div className="next-subject">
                <button className="btn" onClick={() => nextSubject()}>
                  Next Subject
                </button>
              </div>
            </div>
            <div className="btnPanelWrap pb-4">
              {allQuestion?.questions?.map((value, index) => (
                <>
                  <button
                    className={
                      value.question == selectedQuestion
                        ? "btn selectedSub"
                        : "btn"
                    }
                    onClick={() => questionNumberButton(value.question, index)}
                  >
                    {index + 1}
                  </button>
                </>
              ))}
            </div>
          </>
          <div className="qucomm-btn-wrap center-submit-btn">
            <button
              className="btn big-btn exam-submitbtn"
              onClick={() => setIsModalOpen(true)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default JAMBquestion;