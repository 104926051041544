import React, { useEffect, useState } from "react";
import AuthCard from "../../../Components/AuthCard";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "antd";
import { Form, Checkbox, Button } from "antd";
import { Login } from "../../../services";
import { LOGIN_API } from "../../../services/ApiConstant";

const AmbassodorSignin = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const [email,setEmail]=useState('')
  const [password,setPass]= useState('')
  const [error,setError]=useState('')
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  
useEffect(()=>{
  setEmail(getCookie("email"))
  setPass(getCookie("password"))
},[])

  const onFinish = (values) => {
    setIsLoading(true);
    Login(LOGIN_API, values).then((res) => {
      setIsLoading(false);
      if(values.remember===true){
        document.cookie = "email="+values.email
        document.cookie = "password="+values.password
      }
      else{
        document.cookie = "email="+values.email+'=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        document.cookie = "password="+values.password+'=;  Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      }
      localStorage.setItem('token',res?.data?.token);
      // localStorage.setItem('userType',res?.data?.id)
      navigate('/ambassodor/ambassodor-overview')
    }).catch((e)=>{
      setIsLoading(false);
      setError(e.response.data)
    })
  };
  return (
    <AuthCard
      link="/amb-create-account"
      linkText={"Create an Account"}
      subtitle="Don’t have an account? click here to"
      isHide={true}
      loginPage={true}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item label="Email Address" name="email" 
        rules={[{ required: true, type:'email', message: 'Please input your email!' }]}
        >
          <Input placeholder="Enter your email" defaultValue={email} />
        </Form.Item>
        {<div className="error-msg">{error?.detail}</div>}
        <Form.Item label="Password" name="password" 
        rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input type="password" placeholder="Enter your password" defaultValue={password} />
        </Form.Item>
        {<div className="error-msg">{error?.detail}</div>}
        <Form.Item className="height-unset">
          <div className="row-between">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Link to="/forgot-password">
              <div className="login-form-forgot">Forgot password</div>
            </Link>
          </div>
        </Form.Item>
        <br />
        <br />
        <Button className="auth-btn login--btn" type="primary" onClick={()=> form.submit()} loading={isLoading}>
          {" "}
          Log in{" "}
        </Button>
               

      </Form>
    </AuthCard>
  );
};

export default AmbassodorSignin;
