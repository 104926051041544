import MathJax from "react-mathjax-preview"

const Question = ({ question, no }) => {
    let q = question?.replace(`xmlns=\\\"http://www.w3.org/1998/Math/MathML\\\"`, ``)
    q = q?.replace(`xmlns=\\"http://www.w3.org/1998/Math/MathML\\"`, ``)
    return (
        <>
            <div className="d-flex">
                {no!==undefined && <span className="">
                    {no}.
                </span>}
                &nbsp;
                
                <MathJax math={q} />
            </div>
        </>
    )
}
export default Question
