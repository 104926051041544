import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs } from 'antd';
import LevelExam from "./LevelExam";
import { getRequest, getUserData } from "../../services";
import { GET_BLOG_DETAILS, GET_EXAM_TYPES } from "../../services/ApiConstant";

const OtherExam = ({ step, page }) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(null)
  const onChange = (key) => {
  };
  const [examTypes, setExamTypes] = useState([]);
  const [blog, setBlog] = useState([])
  const items = []
  useEffect(() => {
    getUserData(GET_EXAM_TYPES).then((res) => {
      setExamTypes(res?.data);
    })
    getRequest(GET_BLOG_DETAILS).then((res) => {
      setBlog(res?.data);
    })
  }, [])

  examTypes?.map((item, index) => {
    items[index] = { label: item?.name, key: index.toString(), children: <LevelExam item={item} /> }
  })

  const blogHandler=(item)=>{
    const data=blog?.filter((currBlog)=>{
      return currBlog?.title==item
    })[0]?.external_link
    window.location.replace(data)
  }
  
  return (
    <div className="w-100 flex-wrap" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="w-70 others-exam-body">
        <Tabs size="large" defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
      <div className="exam-right">
        <br />
        <h1>Blogs</h1>
        <br />
        {
          blog?.map((item,index) => (
            <>
              <div className="d-flex right-card align-items-center justify-content-between" style={{cursor:'pointer'}} >
                <div className="blogtham">
                  <img src={item.icon} onClick={()=>blogHandler(item?.title)} />
                </div>
                <div className="blog-text">
                  <h1 className="title  one-line-title" onClick={()=>blogHandler(item?.title)}>{item.title}</h1>
                  {/* <BlogData details={item.details} /> */}
                  <p className="desc">
                    {showMore==index ? item.details : `${item.details.substring(0, 50)}`} 
                    {!(showMore==index) ? <a  name={index} className="blog-read-more" onClick={(e) => { setShowMore(e.target.name) }}> Read more</a> : <a  name={index} className="blog-read-more"  onClick={(e) => { setShowMore(null) }}> Show less</a>}
                  </p> 
                </div>
              </div>
              <br />
            </>
          ))
        }
      </div>
    </div>
  );
};

export default OtherExam;