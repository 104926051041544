import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { GET_TESTPAPER_BY_SUBJECT_ID, GET_USER_EXAM_DETAILS } from "../../services/ApiConstant"
import { getUserData } from "../../services";

const IELTSSimulation = () => {
  const navigate = useNavigate();
  const [subject, setSubject] = useState([])
  const [year, setYear] = useState([])
  const [yearData, setYearData] = useState()
  const [listeningData, setListeningData] = useState([
    {year: 2000, testpaper_id: 95, is_calculator: false, exam_subject_id: 68, subject_name: 'Listening'}])
  const [readingData, setReadingData] = useState([])
  let subject_id = []
  const [selectedExam, setSelectedExam] = useState(null)


  const onYearSelect = (Selectedexam) => {
    setSelectedExam(Selectedexam)
  }

  const onStartExam = () => {
    if (selectedExam !== undefined) {
      localStorage.setItem("testPaperID", selectedExam.testpaper_id)
      localStorage.setItem("examName", selectedExam.subject_name)
      localStorage.setItem("SelectedYear", selectedExam.year)
      switch (selectedExam.subject_name) {
        case "Reading":
          navigate('/exam/ielts-instruction')
          break;

        case "Listening":
          navigate('/exam/ielts-listening-demo')
          break;

        default:
          break;
      }
    }
  }


  useEffect(() => {
    let examID = localStorage.getItem("examID")
    getUserData(GET_USER_EXAM_DETAILS + `/?exam_id=${examID}`).then((res) => {
      setSubject(res.data)
      res?.data?.map((currExam) => {
        subject_id.push(currExam.exam_subject_id)
      })
      setYear(subject_id.join())
      setSubject((prevSubject) => [...prevSubject, yearData]);
    })
  }, [])

  useEffect(() => {
    if (year.length > 0) {
      getUserData(GET_TESTPAPER_BY_SUBJECT_ID + `?exam_subjects_ids=${year}`).then((res) => {
        // setYearData(res?.data)
        res.data?.map((i) => {
          if (i.subject_name === "Reading") {
            setReadingData([...readingData, i])
          }
          if (i.subject_name === "Listening") {
            setListeningData([...readingData, i])
          }

        })

      })
    }
  }, [year])

  return (
    <div className="jabm-sec">
      <div className="jabm-container">
        <div className="listening-panel">
          <div className="listening-ttl">
            <div className="tham-icon">
              <img src="../images/headphones.svg" alt="" />
            </div>
            <h2>IELTS Listening Test</h2>
          </div>
          <div className="listening-wrap">
            <div className="radio-btn-wrap center-radio">
              {
                listeningData?.map((currSubject) => {
                  return <div className="btn-box mb-3">
                    <input type="radio" id={currSubject?.subject_name} name="ielts" value={currSubject?.subject_name} onClick={() => onYearSelect(currSubject)} />
                    <label htmlFor={currSubject?.subject_name}><span className="hd-icon"><img src="../images/color-headphones-icon.svg" alt="" /></span>{currSubject?.subject_name} Test - <strong>{currSubject?.year}</strong></label>
                  </div>
                })
              }

            </div>
          </div>
        </div>
        <div className="listening-panel">
          <div className="listening-ttl">
            <div className="tham-icon">
              <img src="../images/book.svg" alt="" />
            </div>
            <h2>IELTS Reading Test</h2>
          </div>
          <div className="listening-wrap">
            <div className="radio-btn-wrap center-radio">
              {
                readingData?.map((currSubject) => {
                  return <div className="btn-box">
                    <input type="radio" id={currSubject?.subject_name} name="ielts" value={currSubject?.subject_name} onClick={() => onYearSelect(currSubject)} />
                    <label htmlFor={currSubject?.subject_name}><span className="hd-icon"><img src="../images/ielts-book.png" alt="" /></span>{currSubject?.subject_name} Test - <strong>{currSubject?.year}</strong> </label>
                  </div>
                })
              }

            </div>
            { <div className="qucomm-btn-wrap">
              <button className="btn" disabled={!(selectedExam !== null)} onClick={onStartExam}>Start exam</button>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IELTSSimulation;
