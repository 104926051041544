import { Col, Row } from 'antd';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GET_ANSWERS_BY_QUESTION_IDS, GET_QUESTIONS_BY_TESTPAPER_IDS, GET_TESTPAPER_QUESTION_ANSWER, GET_USER_TESTPAPER_STATUS, IELTS_GET_ANSWER_BY_USER_TESTPAPER, IELTS_GET_QUESTION_BY_TESTPAPER_IDS } from "../../services/ApiConstant";
import { getUserData, postRequest } from "../../services";
import { Modal } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Question from '../../assets/common/Question';
import Options from '../../assets/common/Option';

const ReadingAns = () => {

    const navigate = useNavigate();
    const [allData, setAllData] = useState([])
    const [group, setGroup] = useState([])
    const [timer, setTimer] = useState()
    let testPaperID = localStorage.getItem("testPaperID")
    let examID = localStorage.getItem("examID")
    let time = localStorage.getItem("ExamTime")
    let examName = localStorage.getItem("examName")
    let exam = localStorage.getItem("exam")
    const [firstQuestion, setFirstQuestion] = useState(0)
    const [taskNo, setTaskNo] = useState(0)
    const [showPassage, setShowPassage] = useState(true)
    const [answer, setAnwser] = useState([])

    useEffect(() => {
        getUserData(IELTS_GET_QUESTION_BY_TESTPAPER_IDS + `?testpaper_id=${testPaperID}`).then((res) => {
            setAllData(res.data)
        });
    }, [])

    useEffect(() => {
        getUserData(GET_TESTPAPER_QUESTION_ANSWER + `?exam_id=${examID}&testpaper_id=${testPaperID}`).then((res) => {
            setAnwser(res.data)
        });
    }, [])

    useEffect(() => {
        if (allData[taskNo]?.questions.length <= firstQuestion) {
            setTaskNo(taskNo + 1)
            setShowPassage(true)
            setFirstQuestion(0)
        }
    }, [firstQuestion])

    const ansChecking = (ans, question) => {
        let status = { correct_answer: false, your_answer: false }
        answer?.map((i) => {
            if (i.question == question) {
                status.correct_answer = i.correct_answer == ans
                status.your_answer = i.user_answer == ans
            }
        })
        return status
    }

    const onContinueButton = () => {
        !showPassage && setFirstQuestion(firstQuestion + 4)
        setShowPassage(false)
    }

    const onBackButton = () => {
        firstQuestion == 0 && setShowPassage(true)
        !firstQuestion == 0 && setFirstQuestion(firstQuestion - 4)
        firstQuestion == 0 & showPassage && setTaskNo(taskNo - 1)
        firstQuestion == 0 & showPassage && setFirstQuestion(allData[taskNo - 1]?.questions.length - allData[taskNo - 1]?.questions.length % 4)
        firstQuestion == 0 & showPassage && setShowPassage(false)
    }

    return (
        <>
            <div className="q-set-sec">
                <div className="q-set-top-bar">
                    <div className="q-set-top-bar-wrap">
                        <div className="set-number"><strong>1</strong></div>
                        <div className="q-set-timing-box"><img src="/images/stopwatch.svg" /> <span>{timer}</span></div>
                        <div className="set-number"><strong>1</strong></div>
                    </div>
                </div>
                {showPassage && <>
                    <div className="q-set-ttl">
                        <div className="q-set-ttl-wrap">
                            {exam == "SAT" ? <div><h1>{examName} Test</h1> <br /> <h4> {time} MINUTES, {group.length} QUESTIONS </h4> <br /> </div> : <h2>NOVEMBER 1989 SSCE COMMERCE OBJECTIVE</h2>}
                        </div>
                    </div>
                    <div className="q-set-desc">
                        <button className="q-set-btn">DIRECTIONS</button>
                        <p className="mt-4">Each passage or pair of passages below is followed by a number of questions. After reading each passage or pair, choose the best answer to each question based on what is stated or implied in the passage or passages and in any accompanying graphics (such as a table or graph).</p>
                    </div>
                </>}
                <div className="q-set-wraper pt-0">
                    <div className="q-set-text-wrap">
                        <Row gutter={{ xs: 30, sm: 30, md: 35, lg: 40 }}>

                            {showPassage && <Col span={12} className='border-rt-dott'>
                                <div className="q-set-text">
                                    <h3>Questions 1-{allData[taskNo]?.questions.length} are based on the following passage.</h3>

                                    <i>
                                        <div className="task-box " dangerouslySetInnerHTML={{ __html: allData[taskNo]?.paragraph.replace(/\n/g, "<br />") }}></div>
                                    </i>

                                </div>
                            </Col>}

                            {!showPassage &&
                                <>
                                    {allData[taskNo]?.questions?.slice(firstQuestion, firstQuestion + 4).map((i, index) => {
                                        return <Col span={12}> <div className="para-q-text">
                                            <div className="num-ttl">
                                                <h4>{firstQuestion + index + 1}</h4>
                                            </div>
                                            <p>
                                            <Question question={i.question_details.question_text}/>  
                                            </p>
                                            <p>{i.question_details.question_note}</p>
                                            {
                                                i?.answer.map((j, index) => (
                                                    <ul className="cusradiolist">
                                                        <li>
                                                            <input type="radio" id={j.ans+i?.question} name={i?.question} value={j.ans} checked={ansChecking(j.ans, i?.question).your_answer} />
                                                            <label htmlFor={j.ans+i?.question}><Options option={j?.ans} index={index}/></label> &nbsp;&nbsp;
                                                            {!ansChecking(j.ans, i?.question).correct_answer && ansChecking(j.ans, i?.question).your_answer && <CloseOutlined />}
                                                            {ansChecking(j.ans, i?.question).correct_answer && <CheckOutlined />}
                                                        </li>
                                                    </ul>
                                                ))
                                            }
                                        </div>
                                        </Col>
                                    })}
                                </>}
                        </Row>

                        <div className="q-set-btn-wraper">
                            <button className="submit-btn" onClick={() => navigate("/exam")}>Go Home</button>
                            <div>
                                {!(taskNo == 0 & showPassage) && <button className="continue-btn back-btn" onClick={() => onBackButton()}>back</button>}
                                {!(allData?.length == taskNo + 1 & allData[taskNo]?.questions?.length <= firstQuestion + 4 & !showPassage) && <button className="continue-btn" onClick={() => onContinueButton()}>continue</button>}

                                {/* {!(taskNo >= allData.length - 1 & firstQuestion + allData[taskNo - 1]?.questions.length % 4 >= allData[taskNo]?.questions.length) && <button className="continue-btn" onClick={() => onContinueButton()}>continue</button>} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReadingAns;
