import { useEffect, useState } from "react";
import { getUserData } from "../../services";
import { GET_JAMB_EXAM_QUESTIONS, GET_USER_EXAM_DETAILS, JAMB_GET_TESTPAPER_QUESTION_ANSWERS } from "../../services/ApiConstant";
import {  useNavigate } from "react-router-dom";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import Question from "../../assets/common/Question";
import Options from "../../assets/common/Option";


const UTMEAnswer = () => {
    const [subject, setSubject] = useState()
    const navigate = useNavigate();
    const [examSubjectIds, setExamSubjectIds] = useState()
    const [allQuestion, setAllQuestion] = useState()
    const [selectedQuestion, setSelectedQuestion] = useState()
    const [displayQuestion, setDisplayQuestion] = useState()
    const [group, setGroup] = useState([])
    const [testPaper, setTestPaper] = useState(localStorage.getItem("selectedTestpaper"))
    const [testPaperBunch, setTestPaperBunch] = useState([])
    const [show, setShow] = useState(false)
    const [arrayOfQuestion, setArrayOfQuestion] = useState([])
    const [arrayOfSubject, setArrayOfSubject] = useState([])
    const [answer, setAnwser] = useState()
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [error, setError] = useState()
    let examID = localStorage.getItem("examID")
    const logo = localStorage.getItem("Logo")
    const examName = localStorage.getItem("examName")

    useEffect(() => {
        const selectedSubArray = localStorage.getItem("subjectSelected").split(",")
        getUserData(GET_USER_EXAM_DETAILS + `/?exam_id=${examID}`).then((res) => {
            let exam = []
            setSubject(res.data?.filter((i) => (selectedSubArray.includes(i.exam_subject_id.toString()))))
            let array = []
            res.data?.filter((i) => (selectedSubArray.includes(i.exam_subject_id.toString()))).map((i) => {
                !(array.includes(i.exam_subject_id)) && setArrayOfSubject(arrayOfSubject => [...arrayOfSubject, i.exam_subject_id])
                array.push(i.exam_subject_id)
            })
        });

        getUserData(JAMB_GET_TESTPAPER_QUESTION_ANSWERS + `?exam_id=${examID}&testpaper_id=${testPaper}`).then((res) => {
            setAnwser(res.data)
        });

    }, [])

    useEffect(() => {
        setExamSubjectIds(arrayOfSubject[0])
    }, [arrayOfSubject])

    let yearSelected = localStorage.getItem("yearSelected")
    useEffect(() => {
        setSelectedQuestion('')
        setCurrentQuestionIndex(0)
        setDisplayQuestion()

        examSubjectIds !== undefined && getUserData(GET_JAMB_EXAM_QUESTIONS + `?exams_subjects=${examSubjectIds}&paper_year=${yearSelected}`).then((res) => {
            setError('')
            let ans = []
            setAllQuestion(res.data[0])
            setArrayOfQuestion([])
            let array = []
            res.data[0].questions?.map((i) => {
                if (!(array.includes(i.question))) {
                    setArrayOfQuestion(arrayOfQuestion => [...arrayOfQuestion, i.question])
                    array.push(i.question)
                }
            })
            let testPaper = res.data[0].testpaper_id
            let newobj = {}
            res.data[0].questions.map((i) => {
                ans = [...ans, { question_id: i.question, answer: "", attempted: false }]
            })
            newobj[testPaper] = ans
            if (!(testPaperBunch?.includes(testPaper))) {
                setTestPaperBunch([...testPaperBunch, testPaper])
                setGroup([...group, newobj])
            }
        }).catch((err) => {
            setError(err.response.data.error)
            setArrayOfQuestion([])
            setAllQuestion([])
        });
    }, [examSubjectIds])

    useEffect(() => {
        setSelectedQuestion(arrayOfQuestion[0])
    }, [arrayOfQuestion])

    useEffect(() => {
        setDisplayQuestion(allQuestion?.questions?.filter((data) => (data.question === selectedQuestion)))

    }, [selectedQuestion])

    const selectChoice = (e, subject, question) => {
        setShow(!show)
        group.map((i) => {
            i[allQuestion?.testpaper_id]?.map((i) => {
                if (i.question_id == question) {
                    i.answer = e.target.value
                    i.attempted = true
                }
            })
        })
    }

    const ansChecking = (ans, question) => {
        // setShow(!show)
        let status = { correct_answer: false, your_answer: false }
        answer?.map((i) => {
            if (i.exam_id == examSubjectIds) {
                i.corrections_ans.map((i) => {
                    if (i.question == question) {
                        status.correct_answer = i.correct_answer == ans
                        status.your_answer = i.user_answer == ans
                    }
                })
            }
        })
        return status
    }

    const nextSubject = () => {
        let i = null
        i = (arrayOfSubject.indexOf(Number(examSubjectIds)))
        arrayOfSubject.length - 1 > i && setExamSubjectIds(arrayOfSubject[i + 1])
    }

    const nextQuestion = () => {
        if (currentQuestionIndex < arrayOfQuestion.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        let i = null
        i = (arrayOfQuestion.indexOf(Number(selectedQuestion)))
        arrayOfQuestion.length - 1 > i && setSelectedQuestion(arrayOfQuestion[i + 1])
    }

    const backQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
        let i = null
        i = (arrayOfQuestion.indexOf(Number(selectedQuestion)))
        0 < i && setSelectedQuestion(arrayOfQuestion[i - 1])
    }

    const goToDashBorad = () => {
        localStorage.removeItem("result")
        localStorage.removeItem("takenTime")
        localStorage.removeItem("testPaperBunch")
        localStorage.removeItem("yearSelected")
        localStorage.removeItem("selectedTestpaper")
        localStorage.removeItem("Logo")
        localStorage.removeItem("examName")
        localStorage.removeItem("ExamTime")
        localStorage.removeItem("examID")
        localStorage.removeItem("subjectSelected")
        navigate("/exam")
    }

    const questionNumberButton = (questionNo, Index) => {
        setSelectedQuestion(questionNo)
        setCurrentQuestionIndex(Index)
    }

    return (
        <>
            <div className="jabm-sec">
                <div className="jabm-container">
                    {displayQuestion !== undefined && <div className="text-center utme-logo-box  py-5">
                        <img src={logo}></img>
                        <h2>{examName}</h2>
                        <h2>Post UTME PAST QUESTION ({yearSelected}) </h2>
                    </div>
                    }
                    <div className="pt-4">
                        <div className="btnPanelWrap pb-4">
                            {subject?.map((i) => (
                                <button className={i.exam_subject_id == examSubjectIds ? "btn selectedSub" : "btn"} value={i.exam_subject_id} onClick={(e) => setExamSubjectIds(e.target.value)} >{i.subject_name}</button>
                            ))}
                        </div>
                    </div>
                    {<div className="error-msg">{error}</div>}
                    <div className="quit-main-wrap pb-4">
                        <p><i>Choose the option that best completes the gap(s).</i></p>
                        <div className="exQupanel">
                            {displayQuestion !== undefined &&
                                <>
                                    {displayQuestion[0]?.diagram && <div className="exQucusupload mb-4">
                                        {/* <i>Use the diagram below to answer question 1, 2 and 3</i> */}
                                        {/* <input type="file" name="" value="" id="upload" /> */}
                                        <label htmlFor="upload">
                                            <img src={displayQuestion[0]?.diagram} alt="" />
                                        </label>
                                    </div>}
                                    <div className="exQucusupload mb-4">
                                        {displayQuestion[0]?.question_details.question_explaination}
                                    </div>
                                    <div className="qutext">
                                        <h3  className="d-flex" style={{alignItems: "center"}} >
                                            <Question question={displayQuestion[0]?.question_details?.question_text} no={currentQuestionIndex + 1}/>  
                                        </h3>
                                    </div>
                                    <div className="quoplist">
                                        {displayQuestion[0]?.question_details.question_note && <div className="exQucusupload mb-4 note-bold">
                                            {displayQuestion[0]?.question_details.question_note}
                                        </div>}
                                        <ul className="cusradiolist">
                                            {displayQuestion[0]?.answer.map((j, index) => (
                                                j.ans !== null && <li>
                                                    <input type="radio" id={j.ans} name={displayQuestion[0]?.question} value={j.ans} checked={ansChecking(j.ans, displayQuestion[0]?.question).your_answer} />
                                                    <label htmlFor={j.ans}><Options option={j?.ans} index={index}/></label>&nbsp;
                                                    {!ansChecking(j.ans, displayQuestion[0]?.question).correct_answer && ansChecking(j.ans, displayQuestion[0]?.question).your_answer && <CloseOutlined />}
                                                    {ansChecking(j.ans, displayQuestion[0]?.question).correct_answer && <CheckOutlined />}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <><div className="quit-btn-panel pb-4">
                        <div className="back-next-side">
                            <button className="btn" onClick={() => backQuestion()} disabled={currentQuestionIndex === 0}><img src="/images/left-arrow-icon.png" alt="" />&nbsp;Back</button>
                            <button className="btn" onClick={() => nextQuestion()} disabled={currentQuestionIndex === arrayOfQuestion.length - 1}>Next&nbsp;<img src="/images/right-arrow-icon.png" alt="" /></button>
                        </div>
                        <div className="next-subject">
                            <button className="btn" onClick={() => nextSubject()} >Next Subject</button>
                        </div>
                    </div>
                        <div className="btnPanelWrap pb-4">
                            {
                                allQuestion?.questions?.map((value, index) => (<>
                                    <button className={value.question == selectedQuestion ? "btn selectedSub" : "btn"} onClick={() => questionNumberButton(value.question, index)} >{index + 1}</button>
                                </>
                                ))
                            }
                        </div></>
                    <div className="qucomm-btn-wrap text-center">
                        <button className="btn big-btn" onClick={() => {
                            goToDashBorad()
                        }}>Go to Dashboard</button>
                    </div>
                </div>
            </div>
        </>)
}
export default UTMEAnswer