import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Result = () => {
  const { state } = useLocation();
  const [result, setResult] = useState(
    JSON.parse(localStorage.getItem("result"))[0]
  );
  const [timer, setTimer] = useState(localStorage.getItem("takenTime"));
  const navigate = useNavigate();
  const ansScreen = localStorage.getItem("AnsScreenOf");

  useEffect(() => {
    localStorage.removeItem("ExamTime");
  });
  window.location.hash = "no-back-button";
  window.onhashchange = function () {
    window.location.hash = "no-back-button";
  };

  const homeButton = () => {
    localStorage.removeItem("takenTime");
    localStorage.removeItem("result");
    localStorage.removeItem("testPaperID");
    localStorage.removeItem("examID");
    navigate("/exam");
  };

  const checkCorrection = () => {
    switch (ansScreen) {
      case "ielts":
        navigate("/exam/ielts-check-corrections");
        break;
      case "ieltsL":
        navigate("/exam/ielts-listening-ans");
        break;
      case "sat-reading":
        navigate("/exam/sat-reading-ans");
        break;
      case "sat-math":
        navigate("/exam/sat-math-ans");
        break;
      // case "jamb":
      //     navigate("/exam/jamb-check-corrections")
      //     break;
      default:
        navigate("/exam/check-corrections");
        break;
    }
  };

  return (
    <>
      <div className="jabm-sec">
        <div className="jabm-container">
          <div className="resultWrap">
            <div className="result-top">
              <div className="box">
                <h4>Overall Performance</h4>
              </div>
              <div className="box">
                {/* {console.log(timer,timer=="undefined")} */}
                <h5>Time Taken : {timer=="undefined"?"00:00:00": timer}</h5>
              </div>
            </div>
            <div className="result-mid">
              <ul>
                <li>
                  <p>Correct answer</p>
                  <p>{result?.answer}</p>
                </li>
                <li>
                  <p>Wrong answer</p>
                  <p>{result?.wrong}</p>
                </li>
                <li>
                  <p>Unanswered</p>
                  <p>{result?.unanswer}</p>
                </li>
              </ul>
            </div>
            <div className="totalpanel">
              <strong>Total Score:</strong>
              <strong>{`${result?.out_of_score}/${result?.total_score}`}</strong>
            </div>
            <div className="btns-panel">
              {/* <button className="btn"onClick={()=>{navigate("/exam/exam-theory")}}>Theory</button> */}
              <button
                className="btn"
                onClick={() => {
                  homeButton();
                }}
              >
                Go Home
              </button>
              <button
                className="btn"
                onClick={() => {
                  checkCorrection();
                }}
              >
                Check Corrections
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Result;
